.header {
  position: fixed;
  min-width: 320px;
  z-index: 999;
  width: 100%;
  height: 48px;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  box-shadow: 0px 0px 3px rgba($black, 15%);
  background-color: $white;
  @media (max-width: $lg) {
    gap: 1rem;
  }
  @media (min-width: $sm) {
    padding: 0.25rem 1rem;
  }

  &__branding {
    &--ocl {
      .header__branding__logo {
        height: 22px;
        width: auto;
      }
      .header__branding__logo--mobile {
        height: 32px;
      }
    }
    &--nj {
      @media (min-width: $lg) {
        min-width: 184px;
      }
    }
    &__logo {
      cursor: pointer;
      min-width: 32px;
      height: 32px;
      &--mobile {
        @media (min-width: $lg) {
          display: none;
        }
      }
      &--desktop {
        @media (max-width: $lg) {
          display: none;
        }
      }
    }
  }

  &__search {
    position: relative;
    width: 100%;
    max-width: 450px;
    @media (min-width: $lg) {
      margin-left: 2rem;
    }
    .select__control {
      min-height: 32px !important;
    }
    .control {
      height: 34px;
      font-size: 14px;
      padding-right: 44px;
    }

    &__btn {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      .btn {
        height: 34px;
        background-color: transparent;
        padding: 0;
        display: flex;
        height: 100%;
        width: 44px;
        justify-content: center;
        align-items: center;
        color: rgba($black, 20%);
        &:hover {
          outline: none;
          color: $success;
        }
        &:hover,
        &:focus {
          outline: none;
        }
      }
    }
  }

  .nav {
    align-items: center;
    flex-wrap: nowrap;
    margin-left: auto;
    &__link {
      position: relative;
      padding: 0.5rem 0.25rem;
      @media (min-width: $sm) {
        margin: 0 0.5rem;
      }

      &:last-of-type {
        padding-right: 0;
        padding-left: 0.5rem;
      }
      img {
        height: 24px;
        min-height: 24px;
        min-width: 24px;
        width: auto;
      }
      &--profile {
        cursor: default;
        img {
          height: 24px;
          width: 24px;
          min-width: 24px;
          border-radius: 100%;
          outline: 0px solid transparent;
          transition: outline $ease-time ease-in-out;
          @media (max-width: $sm) {
            margin-left: 0.5rem;
          }
        }
        &:hover {
          img {
            outline: 4px solid rgba($success, 50%);
          }
          p {
            color: $black;
          }
        }
        span {
          border: 1px solid $success;
          display: block;
          width: 24px;
          height: 24px;
          line-height: 22px;
          font-weight: 600;
          color: $success;
          border-radius: 100%;
          outline: 0px solid transparent;
          transition: all $ease-time ease-in-out;
          font-size: 12px;
          @media (max-width: $sm) {
            margin-left: 0.5rem;
          }
          &:hover {
            outline: 4px solid rgba($success, 50%);
            background-color: $success;
            color: $white;
          }
        }
        .nav {
          &__link {
            padding: 0.5rem 1rem;
            white-space: nowrap;
            font-size: 14px;
          }
          &__divider {
            margin: 0.5rem 0;
          }
          p {
            font-size: 14px;
            padding: 0.5rem 1rem;
            strong {
              font-size: 16px;
              font-weight: 600;
              display: block;
            }
            + p {
              font-style: italic;
              padding-top: 0;
            }
          }
          &:not([href]) {
            &:hover {
              color: $black;
            }
          }
        }

        &.show .dropdown {
          @media (max-width: $xxs) {
            top: 48px;
          }
        }
      }
      &--notifications {
        span {
          position: absolute;
          color: $danger;
          font-size: 12px;
          top: 4px;
          right: -8px;
          width: 16px;
          font-weight: 600;
          text-align: left;
        }
        .dropdown {
          .nav {
            width: 300px;
            max-height: 360px;
            overflow-x: hidden;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: rgba($black, 20%) #eee;
            &::-webkit-scrollbar {
              width: 2px;
              height: 2px;
            }
            &::-webkit-scrollbar-track {
              background: #eee;
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba($black, 0.2);
              border-radius: 10px;
              &:hover {
                background-color: $primary;
              }
            }
            &__link {
              font-size: 14px;
              padding: 0.5rem 0.75rem;
              &:not(:last-of-type) {
                border-bottom: 1px solid $border;
              }
            }
          }
        }
      }
      .dropdown {
        padding: 0.5rem 0;
        right: -0.5rem;
        left: inherit;
        min-width: 250px;

        @media (max-width: $xxs) {
          position: fixed;
          left: 0px;
          top: 58px;
          right: 0;
        }
      }
      &--menu {
        @media (min-width: $lg) {
          display: none;
        }
        span {
          height: 2px;
          background-color: $black;
          display: block;
          width: 20px;
          position: relative;
          transition: background-color $ease-time ease-in-out;
          &:before,
          &:after {
            content: "";
            width: 100%;
            height: 2px;
            background-color: $black;
            position: absolute;
            left: 0;
            transition: all $ease-time ease-in-out;
          }
          &:before {
            margin-top: -6px;
          }
          &:after {
            margin-top: 6px;
          }
        }
      }
    }
  }
}

.sidebar__visible {
  .header {
    .nav__link--menu {
      span {
        background-color: transparent;
        &:before {
          margin: 0;
          transform: rotate(-45deg);
        }
        &:after {
          margin: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}
