
    .pagination{
      display: flex;
      gap: 3px;
      overflow-y: auto;
      justify-content: center;
      // @media (max-width: 400px) {
      //   flex-wrap: wrap;
      // }
      @media (min-width: $md) {
      gap: 0.25rem;
      }
      &__page{
        padding: 0.25rem 0.75rem;
        display: flex;
        min-width: 36px;
        border-radius: 0.25rem;
        align-items: center;
        justify-content: center;
        transition: background-color $ease-time ease-in-out;
        order: 5;
        &:hover{
          background-color: rgba($success, 20%);
        }
        &--current{
          background-color: $success;
          color: $white;
          &:hover{
            background-color: $success;
          }
        }
        &--disabled{
          opacity: .3;
          cursor: not-allowed;
        }
        // @media (max-width: 400px) {
        //   &:nth-child(1){
        //     order: 1;
        //     width: 23%;
        //     margin-bottom: 1rem;
        //   }
        //   &:nth-child(2){
        //     order: 2;
        //     width: 23%;
        //     margin-bottom: 1rem;
        //   }
        //   &:nth-last-child(1){
        //     order: 3;
        //     width: 23%;
        //     margin-bottom: 1rem;
        //   }
        //   &:nth-last-child(2){
        //     order: 4;
        //     margin-bottom: 1rem;
        //     width: 23%;
        //   }
        // }
      }
    }