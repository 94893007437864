.subscribe{
  @extend .page;
  padding-bottom: 1rem;
  @media (min-width: $md) {
    overflow: hidden;
    padding-bottom: 0rem;
  }

  &__inner{
    @extend .content;
  }

  &__header{
    display: flex;
    gap: 0.5rem 1rem;
    border-bottom: 1px solid $border;
    padding: 1rem;
    flex-flow: column;

    @media (min-width: $md) {
      padding: 1rem 1.5rem;
      justify-content: space-between;
      align-items: center;
      flex-flow: row;
      gap: 1rem;
    }

    &__item{
      display: flex;
      gap: 1rem;
      flex-flow: column;

      @media (min-width: $sm) {
        flex-flow: row;
        align-items: center;
      }

    }
  }

  &__content{
    flex-grow: 1;
    @media (min-width: $md) {
      height: calc(100% - 77px);
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &__block{
    display: flex;
    flex-flow: column;
    gap: 1rem;
    padding: 1rem;

    @media (min-width: $md) {
      padding: 1rem 1.5rem 1.25rem;
    }

    &:not(:last-of-type){
      border-bottom: 1px solid $border;
    }
    
    &__head{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.25rem 1rem;
    }

    &__desc{
      width: 100%;
    }

    &__content{
      display: flex;
      flex-flow: column;
      gap: 1.5rem 1rem;
    }
  }

  &__items{
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem 1rem;

    [class^="title--"]{
      width: 100%;
      margin: 0 0 -5px !important;
    }
    &__item{
      cursor: pointer;
      display: inline-flex;
      gap: 0.75rem;
      align-items: center;
      border: 1px solid $border;
      padding: 0.5rem 1.25rem;
      border-radius: 20px;
      transition: all $ease-time ease-in-out;
      outline: 2px solid transparent;
      font-size: 0.85rem;
      @media (min-width: $md) {
        font-size: 1rem;
      }
      &:hover{
        outline: 4px solid rgba($border, 70%);
      }

      &--checked{
        border-color: $success;
        color: $success;
        box-shadow: 0 0 5px rgba($success, 40%), inset 0px -3px 5px rgba($black, 15%);
        outline: 4px solid rgba($success, 20%);

        &:hover{
          outline: 4px solid rgba($success, 20%);
        }
      }
    }

    [class^="title--"]{
      margin-bottom: 0.5rem;
    }
  }
}