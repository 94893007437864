.procedures {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  @media (min-width: $md) {
    height: 100%;
  }
  &__content {
    @extend .content;
    flex-grow: 1;
    padding: 1rem 0.75rem;

    @media (min-width: $md) {
      padding: 1rem 1.5rem;
      overflow-y: auto;
    }
  }
  .reflist__filters {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (min-width: $sm) {
      grid-template-columns: repeat(2, 2fr);
    }
    @media (min-width: $md) {
      grid-template-columns: repeat(4, 1fr);
    }

    .reflist__filters__item {
      width: 100%;
      grid-column: span 2;
      @media (min-width: $sm) {
        grid-column: span 1;
      }
      &:nth-last-child(1),
      &:nth-last-child(2) {
        grid-column: span 1;
      }
    }
  }
  .relative {
    .dropdown {
      padding: 1rem;
      display: flex;
      flex-flow: column;
      ul {
        max-height: 260px;
        overflow-y: auto;
        display: flex;
        flex-flow: column;
        gap: 1rem;
        padding: 1rem;
        margin: 0 -1rem -0.5rem;
      }
    }
  }
  .reflist__content {
    overflow: hidden;
  }
  .reflist__table__row {
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: $sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $md) {
      grid-template-columns: repeat(4, 1fr);
    }

    &--full {
      grid-template-columns: 1fr;
      gap: 0.5rem;
      @media (min-width: $md) {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 0.5rem 1rem;
      }
    }
  }
  .reflist__table__cell {
    &:last-of-type {
      display: block;
    }
    &--priority {
      display: flex !important;
      flex-flow: row !important;
      gap: 0 0.5rem !important;
      @media (min-width: $md) {
        flex-flow: column !important;
        gap: 0 !important;
      }
    }
  }
  .reflist__listing {
    &.active {
      display: grid;
      grid-template-columns: minmax(50%, 1fr) minmax(auto, calc(50% - 1rem));

      @media (min-width: $lg) {
        grid-template-columns: 1fr minmax(50%, 1fr);
      }

      @media (min-width: $xl) {
        grid-template-columns: 3fr 2fr;
      }

      .reflist__table__row {
        grid-template-columns: 1fr;

        @media (min-width: $xl) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: $xxl) {
          grid-template-columns: repeat(4, 1fr);
        }

        &--full {
          grid-template-columns: 1fr;
          gap: 0.5rem;
          @media (min-width: $xxl) {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 0.5rem 1rem;
          }
        }
      }
      .reflist__table__cell {
        &:last-of-type {
          text-align: left;
        }
        &--priority {
          flex-flow: row !important;
          gap: 0 0.5rem !important;

          @media (min-width: $xxl) {
            display: block !important;
          }
        }
      }
    }
  }
  .reflist__details__header {
    flex-wrap: wrap;

    @media (min-width: $lg) {
      flex-wrap: nowrap;
    }
  }
  .reflist__details {
    max-width: inherit !important;
    overflow: hidden;
  }
  .resource-details {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    &__button {
      background-color: #f5f5f5;
      display: flex;
      gap: 0.5rem 1rem;
      justify-content: space-between;
      padding: 0.75rem 0.75rem;
      border-radius: 10px;
    }
    &__box {
      padding: 1rem 1rem;
    }
  }
}
