// Default button color
$btn-background: #f1f1f1;

// Default text color
$text-color: #42526E;

// BORDER COLOR
$border: #ddd;

// Easing time
$ease-time: 300ms;