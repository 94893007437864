.contacts{
  @extend .page;

  &__content{
    flex-grow: 1;
    overflow: auto;
    position: relative;
    @media (min-width: $md) {
      background-color: $white;
      border: 1px solid $border;
    }

    .flex{
      display: flex;
      gap: 1rem;
    }
  }

  &__paging{
    margin-top: 10px;
  }

  .table{
    tr{
      th{
        padding: 0.75rem 1rem;
        white-space: nowrap;
      }
      td{
        vertical-align: middle;
        padding: 0.25rem 1rem;
        font-size: 14px;
        max-width: 1px;
        &:first-of-type{
          width: 40px;
          max-width: 40px;
        }
        &:nth-child(2){
          white-space: nowrap;
          max-width: inherit;
        }
        a, p{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          font-size: 14px;
          display: block;
        }
        a{
          &:hover{
            text-decoration: underline;
          }
        }
        .btn{
          width: auto;
        }
      }
    }
  }

  @media (max-width: $md) {
    .table{
      display: block;
      background-color: transparent;
    
      tbody{
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        @media (max-width: $sm) {
          gap: 0.5rem;
        }
      }
      thead{
        display: none;
      }
      tr{
        width: calc(50% - 0.5rem);
        display: flex;
        flex-flow: column;
        border: 1px solid #ddd;
        border-radius: 6px;
        padding: 1rem 1rem 0.25rem;
        gap: 0.5rem;
        position: relative;
        background-color: $white;
        @media (max-width: $sm) {
          width: 100%;
        }

        td{
          border: 0 !important;
          padding: 0 ;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: inherit;
          &:nth-child(1){
            border-bottom: 1px solid $border !important;
            margin-bottom: 10px;
            padding-bottom: 10px;
            width: 100%;
            max-width: 100%;
          }
          &:nth-child(2){
            position: absolute;
            top: 1rem;
            left: calc(46px + 1rem);
            width: calc(100% - calc(46px + 2rem));
            font-weight: 600;
          }
          &:nth-child(3){
            position: absolute;
            top: 34px;
            font-size: 13px;
            left: calc(46px + 1rem);
            width: calc(100% - calc(46px + 2rem));
          }
          .flex{
            justify-content: flex-end;
          }
        }
      }
    }
  }

  &__init{
    width: 30px;
    min-width: 30px;
    height: 30px;
    border: 1px solid $success;
    color: $success;
    font-weight: 600;
    border-radius: 100%;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $md) {
      width: 36px;
      min-width: 36px;
      height: 36px;
    }
  }
}
