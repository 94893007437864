.intake {
  @extend .page;

  &__content {
    overflow: auto;
    position: relative;
    display: flex;
    flex-grow: 1;
    @media (min-width: $md) {
      background-color: $white;
      // border: 1px solid $border;
    }

    .flex {
      display: flex;
      gap: 1rem;
    }
  }

  &__paging {
    margin-top: 10px;
  }

  &__search {
    display: flex;
    flex-flow: column;

    &__form {
      display: flex;
      align-items: center;
      gap: 1rem;

      @media (max-width: $lg) {
        flex-wrap: wrap;
        .intake__search__item {
          &:first-of-type {
            width: 100%;
            margin-top: 0.5rem;
          }
          &--input {
            flex-grow: 1;
          }
        }
      }
      @media (max-width: $sm) {
        gap: 0.5rem 0;
        .intake__search__item {
          gap: 0;
          &:nth-child(2) {
            width: 100%;
          }
        }
        .radiogroup__item {
          .label {
            font-size: 14px;
          }
        }
      }
    }

    &__item {
      display: flex;
      gap: 1rem;

      &--input {
        width: 30%;
      }

      &:last-of-type {
        margin-left: auto;
      }

      .label {
        white-space: nowrap;
      }
      .btn {
        padding: 0 1rem;

        .fa-arrows-rotate {
          font-size: 1.5rem;
          color: $success;
          transition: transform 1s ease-in-out;
        }

        &--clear {
          &:hover {
            background-color: transparent;
            .fa-arrows-rotate {
              transform: rotate(360deg);
              color: $danger;
            }
          }
        }
      }
    }
  }

  &__table {
    flex-grow: 1;
    // border: 1px solid $border;
    background-color: $white;
    .table {
      position: relative;
      padding: 0;
      min-width: 800px;

      @media (max-width: $sm) {
        display: block;
        min-width: inherit;
      }
    }
    thead {
      th {
        white-space: nowrap;
        font-size: 14px;
        @media (max-width: $sm) {
          padding: 0.5rem !important;
          display: none;
        }
      }
    }
    tbody {
      @media (max-width: $sm) {
        display: block;
      }
      tr {
        transition: background-color $ease-time ease-in-out;
        @media (max-width: $sm) {
          display: flex;
          flex-flow: column;
          gap: 0.5rem;
          padding: 1rem;
          &:not(:last-child) {
            border-bottom: 1px solid $border;
          }
        }
        &:hover {
          background-color: #f5f5f5;
          cursor: default;
        }
        td {
          transition: background-color $ease-time ease-in-out;
          font-size: 14px;

          &:not(:first-of-type) {
            min-width: 160px;
            text-transform: capitalize;
          }
          &:last-of-type {
            min-width: 220px;
          }
          &:nth-child(2) {
            @media (max-width: $sm) {
              font-weight: bold;
              margin-bottom: -0.25rem;
            }
          }
          &:first-of-type {
            display: flex;
            gap: 2rem;
            align-items: center;
            @media (max-width: $sm) {
              order: 6;
              padding-top: 0.5rem;
            }
            img {
              height: 20px;
              min-width: 26px;
              transition: transform $ease-time ease-in-out;
              &:hover {
                transform: scale(1.1);
              }
            }
            span {
              color: $primary;
              transition: transform $ease-time ease-in-out;
              &:hover {
                transform: scale(1.2);
              }
            }
          }
          @media (max-width: $sm) {
            padding: 0;
            border: 0 !important;
          }
        }
      }
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    @media (min-width: $md) {
      flex-wrap: nowrap;
    }
    @media (max-width: $md) {
      gap: 0.75rem;
    }

    &__item {
      position: relative;
      @media (min-width: $md) {
        width: 25%;
        flex-wrap: nowrap !important;
      }
      @media (min-width: $sm) {
        width: calc(50% - calc(0.75rem / 2));
      }
      &--grow {
        flex-grow: 1;
      }
      &:last-of-type {
        width: auto !important;
      }
      &:nth-child(1),
      &:nth-child(2) {
        width: 30%;
      }
      &--sort {
        margin-left: auto;
        flex-grow: unset !important;
        &--show {
          .dropdown {
            opacity: 1;
            visibility: visible;
            top: 100%;
            pointer-events: unset;
          }
        }
        .btn--white {
          position: relative;
          padding-right: 2.5rem;

          &:before {
            content: url("../../Assets/images/asc.svg");
            position: absolute;
            right: 16px;
            top: 13px;
          }

          &:after {
            content: url("../../Assets/images/desc.svg");
            position: absolute;
            right: 24px;
            top: 13px;
          }
        }
        .dropdown {
          left: inherit;
          right: 0;
        }
        .nav {
          &__link {
            white-space: nowrap;
            font-size: 14px;
            padding: 0.35rem 2rem 0.25rem 1rem;
            opacity: 0.7;
            &--asc {
              background: url("../../Assets/images/asc.svg") 94% no-repeat;
              opacity: 1;
              font-weight: 600;
            }
            &--desc {
              background: url("../../Assets/images/desc.svg") 94% no-repeat;
              opacity: 1;
              font-weight: 600;
            }
            &:not(:last-of-type) {
              border-bottom: 1px solid $border;
            }
          }
        }
      }
    }
  }
}
