.privacy{
  @extend .page;
  background-color: $white;
  @media (min-width: $md) {
    background-color: transparent;
  }
  &__content{
    @extend .content;
    overflow-y: auto;
    padding: 0;
    border: 0;
    padding-bottom: 0;
    @media (min-width: $md) {
      padding: 1.5rem;
      padding-bottom: 0;
      border: 1px solid $border;
    }
  }
  h1.title--h2{
    margin-bottom: 0;
  }
  [class*="title--"]{
    color: $primary;
    margin-bottom: 1rem;
  }
  a{
    text-decoration: underline;
    &:hover{
      color: $success;
    }
  }
  p{
    line-height: 1.5;

    &:not(:last-child){
      margin-bottom: 1.5rem;
    }
  }
  ul{
    margin: 0 0 2rem 1rem;
    li{
      position: relative;
      padding-left: 1rem;
      line-height: 1.5;
      &:not(:last-of-type){
        margin-bottom: 1rem;
      }
      &:before{
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $black;
        position: absolute;
        left: 0;
        top: 7px;
      }
    }
  }
}