.invite-add {
  @extend .page;

  &__content {
    @extend .content;
    padding: 1rem 0.75rem;
    @media (min-width: $sm) {
      padding: 1rem 1.25rem;
    }
    @media (min-width: $md) {
      overflow-y: auto;
    }
  }

  .select__single-value {
    strong {
      font-weight: 400;
      // &:after {
      //   content: ": ";
      // }
    }
  }

  .select__menu-list {
    .select__option {
      cursor: pointer;
      font-size: 14px;
      color: $black;
      display: flex !important;
      flex-wrap: wrap;
      span {
        cursor: pointer;
        font-size: 14px;
        color: $black;
        white-space: nowrap;
        mark {
          cursor: pointer;
          font-size: 14px;
          color: $black;
          white-space: nowrap;
        }
      }
      strong,
      strong > span {
        color: $primary;
        font-size: 14px;
        white-space: nowrap;
        mark {
          cursor: pointer;
          font-size: 14px;
          color: $black;
          white-space: nowrap;
        }
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid $border;
      }
      &--is-focused {
        background-color: $lightbg;
      }
      &--is-selected {
        background-color: darken($lightbg, 10%);
      }
    }
  }
}

.invite-edit {
  @extend .page;

  &__content {
    @extend .content;
    padding: 1rem 0.75rem;
    @media (min-width: $sm) {
      padding: 1rem 1.25rem;
    }
    @media (min-width: $md) {
      overflow-y: auto;
    }
  }
}
