.page_content--selectform {
  height: 100vh;
}

.selectform {
  @extend .page;
  height: 100%;
  gap: 8px 0;

  &__content {
    padding: 0;
    @extend .content;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    overflow: hidden;
    @media (min-width: $xl) {
      display: grid;
      grid-template-columns: 460px 1fr;
    }
  }
  &__listing {
    overflow: hidden;
    overflow-x: auto;
    height: 100%;
    display: flex;
    flex-flow: column;
    @media (min-width: $xl) {
      border-right: 1px solid $border;
    }
  }
  &__list {
    flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    &__item {
      padding: 10px 0;
      &:last-of-type {
        padding-bottom: 0;
      }
      &:first-of-type {
        padding-top: 0;
      }
      @media (min-width: $xl) {
        &:first-of-type {
          padding-top: 10px;
        }
      }
    }
  }
  &__header {
    padding: 10px 20px;
    border-bottom: 1px solid $border;
    display: flex;
    align-items: center;
  }
  .checkbox {
    margin: 0;
    align-items: flex-start;
    &__label {
      font-size: 15px;
      text-transform: capitalize;
      line-height: 20px;
      word-break: break-word;
      overflow: unset;
      white-space: unset;
      text-overflow: unset;
    }
    &__info {
      margin-top: 6px;
      text-transform: none;
      strong {
        font-size: 13px;
      }
      p {
        font-size: 13px;
      }
    }
  }
  &__steps {
    border-top: 1px solid $border;
    @media (min-width: $xl) {
      border: 0;
    }
    &__list {
      padding: 20px;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      @media (min-width: $md) {
        grid-template-rows: 200px;
        gap: 20px;
      }
    }
    &__item {
      padding: 10px 20px;
      border: 1px solid $primary;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: $white url(../../Assets/images/gear.svg) right bottom
        no-repeat;
      transition: all $ease-time ease;
      &:hover {
        background: #f9f9f9 url(../../Assets/images/gear.svg) right bottom
          no-repeat;
        box-shadow: 0px 5px 10px rgba($black, 15%);
        cursor: pointer;
      }
      @media (min-width: $md) {
        padding: 20px;
        gap: 24px;
        flex-flow: column;
      }
    }
  }
}
