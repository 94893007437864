.conditions {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  @media (min-width: $md) {
    height: 100%;
  }
  .table--striped {
    td {
      &:first-of-type {
        width: 150px;
      }
    }
  }
  .gap2 {
    gap: 1rem;
    @media (min-width: $xl) {
      gap: 2rem;
    }
  }
  &__content {
    @extend .content;
    flex-grow: 1;
    padding: 1rem 0.75rem;

    @media (min-width: $md) {
      padding: 1rem 1.5rem;
      overflow-y: auto;
    }
  }
  .resource-details {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    &__button {
      background-color: #f5f5f5;
      display: flex;
      gap: 0.5rem 1rem;
      justify-content: space-between;
      padding: 0.75rem 0.75rem;
      border-radius: 10px;
    }
    &__box {
      padding: 1rem 1rem;
    }
  }
}
