.users {
  @extend .page;
  @media (min-width: $md) {
    overflow: hidden;
  }

  &__content {
    @extend .content;
    border: 0;
    background-color: transparent;
    gap: 1rem;
    display: flex;
    flex-flow: column;
  }

  &__filters {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;

    &__item {
      position: relative;

      &--search {
        flex-grow: 1;
        max-width: 450px;
        @media (min-width: $md) {
          min-width: 300px;
        }
      }

      &--sort {
        margin-left: auto;
        &--show {
          .dropdown {
            opacity: 1;
            visibility: visible;
            top: 100%;
            pointer-events: unset;
          }
        }
        .btn--white {
          position: relative;
          padding-right: 2.5rem;

          &:before {
            content: url("../../Assets/images/asc.svg");
            position: absolute;
            right: 16px;
            top: 10px;
          }

          &:after {
            content: url("../../Assets/images/desc.svg");
            position: absolute;
            right: 24px;
            top: 10px;
          }
        }
        .dropdown {
          left: inherit;
          right: 0;
        }
        .nav {
          &__link {
            white-space: nowrap;
            font-size: 14px;
            padding: 0.35rem 2rem 0.25rem 1rem;
            opacity: 0.7;
            &--asc {
              background: url("../../Assets/images/asc.svg") 94% no-repeat;
              opacity: 1;
              font-weight: 600;
            }
            &--desc {
              background: url("../../Assets/images/desc.svg") 94% no-repeat;
              opacity: 1;
              font-weight: 600;
            }
            &:not(:last-of-type) {
              border-bottom: 1px solid $border;
            }
          }
        }
      }
    }
  }

  &__list {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
    flex-grow: 1;

    @media (min-width: $md) {
      overflow-y: auto;
    }

    @media (min-width: $md) {
      gap: 0.75rem;
    }
  }

  .table {
    th,
    td {
      font-size: 0.85rem;
      padding: 0.75rem;
    }
    th {
      font-weight: 600;
      white-space: nowrap;
      color: $primary;
    }
    td {
      word-break: break-word;
    }
  }
}
