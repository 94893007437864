
.observations{
  .reflist__filters{
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: $sm) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .reflist__filters__item{
        grid-column: span 2;
        &--sort{
          grid-column: span 1;
          margin-top: 0.5rem;
        }
      }
    }
    @media (min-width: $xl) {
      grid-template-columns: 1fr 1fr 270px 1fr 100px;
      .reflist__filters__item{
        grid-column: span 1;
        &--sort{
          grid-column: span 1;
        }
      }
    }
    @media (min-width: $xxl) {
      grid-template-columns: repeat(4, 1fr) 100px;
    }
  }
  .reflist__filters__item{
    width: 100% !important;
    &--sort{
      .dropdown{
        left: 0 !important;
        right: inherit !important;
        @media (min-width: $sm) {
          left: inherit !important;
          right: 0 !important;
        }
      }
    }
  }
  .reflist__filters__item__grid{
    display: grid;
    grid-template-columns: 1fr 1fr;


    &--left{
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 0.5rem 30px 0.5rem 0.5rem;
      background-position: right 0.5rem center;
      background-size: 14px;
    }
    &--right{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 0.5rem 30px 0.5rem 0.5rem;
      background-position: right 0.5rem center;
      background-size: 14px;
    }

  }
  .reflist__details{
    .reflist__details__body strong{
      word-break: break-word;
    }
  }
  .reflist__listing {
    &.active{
    .reflist__table__row{
      &--full{
        @media (max-width: $xxl) {
          flex-flow: column;
          align-items: flex-start;
          gap: 0;
        }
      }
    }
    }
  }
}