.questionnaires {
  .reflist__filters__item {
    @media (min-width: $md) {
      &:last-of-type {
        @media (min-width: $xl) {
          width: calc(33.33% - 1rem) !important;
        }
        .btn {
          float: right;
        }
      }
    }
  }
  .reflist__details__tablelist {
    &__grid {
      display: grid;
      grid-template-columns: 190px 1fr;

      &:nth-child(odd) {
        background-color: $secondary;
      }
    }
    &__cell {
      padding: 0.5rem 1rem;
    }
  }

  &__filters {
    display: grid;
    gap: 0.5rem 1rem;

    @media (min-width: $sm) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: $md) {
      grid-template-columns: repeat(3, 1fr) 100px;
    }
  }
  .reflist__details__tablelist__cell {
    word-break: break-word;
  }
}
