.signin {
  background: #004a93;
  background: -moz-linear-gradient(
    -45deg,
    #004a93 0%,
    #008092 69%,
    #008392 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #004a93 0%,
    #008092 69%,
    #008392 100%
  );
  background: linear-gradient(135deg, #004a93 0%, #008092 69%, #008392 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004a93', endColorstr='#008392',GradientType=1 );
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;

  &__wraper {
    display: flex;
    align-items: center;
    overflow-y: auto;
    min-height: 100%;

    @media (min-width: $sm) {
      min-height: calc(100% - (1.75rem * 2));
      max-width: 480px;
      margin: 1.75rem auto;
    }
  }

  &__inner {
    background-color: $white;
    padding: 2rem 2.5rem;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    justify-content: center;
    @media (min-width: $sm) {
      border-radius: 2rem 2rem 0;
      height: auto;
    }
  }

  &__logo {
    width: 100%;
    img {
      display: block;
      margin: 0 auto 1.5rem;
      max-height: 66px;
      width: auto;
    }
  }

  &__slogan {
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    width: 100%;
    p {
      font-size: 0.9rem;
    }
  }

  &__form {
    margin: 2rem auto;
    width: 100%;
  }

  .pwd {
    position: absolute;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    height: 100%;
    right: 0;
    top: 0;
    opacity: 0.5;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity $ease-time ease-in-out;
    &:hover {
      opacity: 0.75;
    }
  }

  .formgroup {
    &.relative {
      margin-top: 2rem;
    }
  }

  [data-testid="oval-loading"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba($white, 90%);
    border-radius: 0.25rem;
  }

  &__forgotpwd {
    text-align: center;
    span {
      color: $danger;
      display: inline-block;
      margin-bottom: 1rem;
      cursor: pointer;

      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }
  }

  &__login {
    text-align: center;
    display: inline-block;
    margin-bottom: 1rem;
    cursor: pointer;

    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }

  &.forgotPwd {
    .signin__slogan {
      max-width: inherit;
    }
  }
}
