$font-size: 2rem !default;
$font-weight: 600 !default;
$font-margin: 0 !default;

h1,
.title--h1 {
  font-size: $font-size;
  font-weight: $font-weight;
  margin: $font-margin 0;
}

h2,
.title--h2 {
  font-size: $font-size * 0.8;
  font-weight: $font-weight;
  margin: $font-margin 0;
}

h3,
.title--h3 {
  font-size: $font-size * 0.7;
  font-weight: $font-weight;
  margin: $font-margin 0;
}

h4,
.title--h4 {
  font-size: $font-size * 0.625;
  font-weight: $font-weight;
  margin: $font-margin 0;
}

h5,
.title--h5 {
  font-size: $font-size * 0.55;
  font-weight: $font-weight;
  margin: $font-margin 0;
}

h6,
.title--h6 {
  font-size: $font-size * 0.5;
  font-weight: $font-weight;
  margin: $font-margin 0;
}

.title--xl {
  font-size: 3rem;
}

.title {
  &--regular {
    font-weight: 400;
  }
  &--light {
    font-weight: 300;
  }
}
