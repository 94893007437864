// .loader-wrapper {
//   height: inherit;
//   width: inherit;
//   display: flex;
//   padding: 3px;
//   position: relative;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   justify-content: center;
//   align-items: center;

//   &__absolute {
//     position: absolute;
//   }
// }


.loader {
  position: absolute;
  left: 0; 
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__signin{
    background-color: rgba($white, 90%);
  }

  &__wraper{
    display: flex;
    align-items: center;
    gap: 0 0.25rem;
    font-size: 1.1rem;
    color: $primary;
    &__dot{
      width: 4px;
      height: 12px;
      margin: 0;
      background-color: $primary;
      animation: loadingAnim 1s ease-in-out 0ms infinite;
      background-color: currentColor;
      &:nth-child(2){
        animation: loadingAnim 1s ease-in-out 200ms infinite;
      }
      &:nth-child(3){
        animation: loadingAnim 1s ease-in-out 400ms infinite;
      }
    }
  }
  &--fullpage {
    position: fixed;
  }
}
@keyframes loadingAnim {
  0%,
  80%,
  100%{opacity:0;}
  40%{opacity:1;}
}
@-webkit-keyframes loadingAnim {
  0%,
  80%,
  100%{opacity:0;}
  40%{opacity:1;}
}
@-moz-keyframes loadingAnim {
  0%,
  80%,
  100%{opacity:0;}
  40%{opacity:1;}
}