$btn-font-size: 1rem !default;
$btn-font-weight: 600 !default;
$btn-border-radius: 0.25rem !default;
$btn-border-width: 1px !default;
$btn-padding-y: 0.5rem !default;
$btn-padding-x: 1.5rem !default;
$btn-padding-y-sm: 0.304rem !default;
$btn-padding-x-sm: 0.5rem !default;
$btn-padding-y-lg: 0.75rem !default;
$btn-padding-x-lg: 2rem !default;
$btn-border-color: transparent !default;
$btn-box-shadow: none !default;
$btn-background: $btn-background !default;
$btn-hover-darkness: 10% !default;
$control-height: 44px;
$control-height-sm: 36px;
$control-height-lg: 56px;
$control-height-xsm: 26px;

.btn {
  line-height: initial;
  border: 0px;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  font-weight: $btn-font-weight;
  height: $control-height;
  box-shadow: $btn-box-shadow;
  border-radius: $btn-border-radius;
  padding: $btn-padding-y $btn-padding-x;
  border: 1px solid $btn-border-color;
  font-size: $btn-font-size;
  text-decoration: none;
  background: $btn-background;
  transition: color $ease-time ease-in-out, border-color $ease-time ease-in-out,
    background-color $ease-time ease-in-out, box-shadow $ease-time ease-in-out,
    outline $ease-time ease-in-out;
  white-space: nowrap;
  cursor: pointer;
  color: set-color($btn-background);

  &:hover {
    background: darken($btn-background, $btn-hover-darkness);
  }

  &:focus {
    outline: 2px solid rgba($primary, 50%);
  }

  &--icon {
    border: 0;
    padding: 0;
    height: 30px;
  }

  &--align-inherit {
    align-items: inherit;
  }

  &--inline {
    display: inline-flex;
  }

  &--noheight {
    height: auto;
  }

  &--icon-lt {
    svg {
      font-size: inherit;
      margin-right: 0.5rem;
    }
  }

  &--icon-rt {
    svg {
      font-size: inherit;
      margin-left: 0.5rem;
    }
  }

  &--noborder {
    border-color: transparent !important;
  }

  // Clear
  &--clear {
    background-color: transparent !important;
    outline: 0 !important;
    font-size: 1rem;

    &:hover {
      color: $success !important;
    }
  }

  // Primary Button
  &--primary {
    background: $primary;
    color: set-color($primary);

    &:hover {
      background: darken($primary, $btn-hover-darkness);
    }

    // Primary outline button
    &[variant="outline"] {
      background: $white;
      color: $primary;
      border-color: $primary;

      &:hover {
        background-color: $primary;
        color: $white;
      }
    }

    // Primary Icon button
    &[variant="icon"] {
      background: transparent;
      color: $primary;
      border-color: transparent;
      padding: $btn-padding-y $btn-padding-x * 0.5;
      height: auto;

      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }

  // Secondary button
  &--secondary {
    color: $white;
    padding: 10px 15px;
    border: 0px;
    background: rgba($black, 55%);

    &:hover {
      color: $white !important;
      background: rgba($black, 0.7) !important;
      border-color: rgba($black, 0.7) !important;
    }

    // Secondary outline button
    &[variant="outline"] {
      background: $white;
      color: set-color($secondary);
      border-color: $secondary;
      &:hover {
        background-color: $secondary;
        color: set-color($secondary);
      }
    }

    // Secondary Icon button
    &[variant="icon"] {
      background: transparent;
      color: $secondary;
      border-color: transparent;
      padding: $btn-padding-y $btn-padding-x * 0.5;
      height: auto;

      &:hover {
        background-color: $secondary;
        color: $white;
      }
    }
  }

  // Success button
  &--success {
    background: $success;
    color: $white;

    &:hover {
      background: darken($success, $btn-hover-darkness);
    }

    // Success outline button
    &[variant="outline"] {
      background: $white;
      color: $success;
      border-color: $success;
      &:hover {
        background-color: $success;
        color: $white;
      }
    }

    // Success Icon button
    &[variant="icon"] {
      background: transparent;
      color: $success;
      border-color: transparent;
      padding: $btn-padding-y $btn-padding-x * 0.5;
      height: auto;

      &:hover {
        background-color: $success;
        color: $white;
      }
    }
  }

  // Warning button
  &--warning {
    background: $warning;
    color: set-color($warning);

    &:hover {
      background: darken($warning, $btn-hover-darkness);
    }

    &-light {
      background: $yellow;
      color: $white;
      &:hover {
        background: darken($yellow, $btn-hover-darkness);
      }
      &[variant="outline"] {
        background: $white;
        color: $yellow;
        border-color: $yellow;
        &:hover {
          background-color: $yellow;
          color: $white;
        }
      }
      &[variant="icon"] {
        background: transparent;
        color: $yellow;
        border-color: transparent;
        padding: $btn-padding-y $btn-padding-x * 0.5;
        height: auto;

        &:hover {
          background-color: $yellow;
          color: $white;
        }
      }
    }

    // Warning outline button
    &[variant="outline"] {
      background: $white;
      color: $warning;
      border-color: $warning;
      &:hover {
        background-color: $warning;
        color: $white;
      }
    }

    // Warning Icon button
    &[variant="icon"] {
      background: transparent;
      color: $warning;
      border-color: transparent;
      padding: $btn-padding-y $btn-padding-x * 0.5;
      height: auto;

      &:hover {
        background-color: $warning;
        color: $white;
      }
    }
  }

  // Danger button
  &--danger {
    background: $danger;
    color: $white;

    &:hover {
      background: darken($danger, $btn-hover-darkness);
    }

    // Danger outline button
    &[variant="outline"] {
      background: $white;
      color: $danger;
      border-color: $danger;
      &:hover {
        background-color: $danger;
        color: $white;
      }
    }

    // Danger Icon button
    &[variant="icon"] {
      background: transparent;
      color: $danger;
      border-color: transparent;
      padding: $btn-padding-y $btn-padding-x * 0.5;
      height: auto;

      &:hover {
        background-color: $danger;
        color: $white;
      }
    }
  }

  // Info button
  &--info {
    background: $info;
    color: set-color($info);

    &:hover {
      background: darken($info, $btn-hover-darkness);
    }

    // Info outline button
    &[variant="outline"] {
      background: $white;
      color: $info;
      border-color: $info;
      &:hover {
        background-color: $info;
        color: $white;
      }
    }

    // Info Icon button
    &[variant="icon"] {
      background: transparent;
      color: $info;
      border-color: transparent;
      padding: $btn-padding-y $btn-padding-x * 0.5;
      height: auto;

      &:hover {
        background-color: $info;
        color: $white;
      }
    }
  }

  // Transparent button
  &--transparent {
    background: transparent;
    color: set-color($white);

    &:hover {
      background: darken($white, $btn-hover-darkness);
    }

    // Transparent outline button
    &[variant="outline"] {
      background: transparent;
      color: rgba(set-color($white), 60%);
      border-color: $border;
      &:hover {
        background-color: transparent;
        border-color: rgba(set-color($white), 30%);
        color: rgba(set-color($white), 100%);
      }
    }

    // Transparent Icon button
    &[variant="icon"] {
      background: transparent;
      color: rgba(set-color($white), 60%);
      border-color: transparent;
      padding: $btn-padding-y $btn-padding-x * 0.5;
      height: auto;

      &:hover {
        background-color: rgba(set-color($white), 60%);
        color: $white;
      }
    }
  }

  // White button
  &--white {
    background: $white;
    color: set-color($white);
    border-color: $border;

    &:hover {
      background: darken($white, $btn-hover-darkness);
    }

    // Danger outline button
    &[variant="outline"] {
      background: $white;
      color: rgba(set-color($white), 60%);
      border-color: $border;
      &:hover {
        background-color: transparent;
        border-color: rgba(set-color($white), 30%);
        color: rgba(set-color($white), 100%);
      }
    }
  }

  // Dark button
  &--dark {
    background: rgba($black, 60%);
    color: set-color(rgba($black, 60%));
    border-color: $border;

    &:hover {
      background: rgba($black, 80%);
    }

    // Danger outline button
    &[variant="outline"] {
      background: transparent;
      color: rgba($black, 60%);
      border-color: rgba($black, 60%);
      &:hover {
        background-color: transparent;
        border-color: $black;
        color: $black;
      }
    }
  }

  // Link style button
  &--link {
    color: $primary;
    background: none;
    padding: 0 !important;
    text-decoration: underline;
    outline: 0 !important;
    &:hover {
      color: $success;
      text-decoration: underline;
      background: none !important;
    }
  }

  &--upload {
    position: relative;

    input[type="file"] {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }

  // Small button
  &--sm {
    font-size: $btn-font-size * 0.9;
    padding: $btn-padding-y-sm $btn-padding-x-sm * 2;
    height: $control-height-sm;
    font-weight: 400;
  }

  // Extra Small button
  &--xsm {
    font-size: $btn-font-size * 0.9;
    padding: $btn-padding-y-sm $btn-padding-x-sm * 2;
    height: $control-height-xsm;
    font-weight: 400;
  }

  // Large button
  &--lg {
    padding: $btn-padding-y-lg $btn-padding-x-lg;
    font-size: $btn-font-size + 3;
    height: $control-height-lg;
  }

  // Full width
  &--full {
    width: 100%;
  }

  // Button with all uppercase letters
  &--uppercase {
    text-transform: uppercase;
  }

  // Button with rounde corners
  &--rounded {
    border-radius: $btn-border-radius;
  }

  // Buttons with disabled attribute
  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &--noBorder {
    border: 0;
  }
  &--nopadding {
    border: 0;
  }
  &--alignRt {
    justify-content: flex-end;
  }
}
