.details{
 @extend .page;
 gap: 1rem;

  &__info {

    &__title{
      display: flex;
      flex-wrap: wrap;
      @media (min-width: $md) {
        align-items: center;
        .btn{
          margin-left: auto;
        }
      }
    }

    &__grid{
      background-color: $white;
      border: 1px solid $border;
      padding: 0.75rem 1rem;
      display: grid;
      gap: 1rem;
      margin-top: 0.3rem;

      @media (min-width: $md) {
        grid-template-columns: 1fr 1fr;
      }
    }

     &__item{
      &--full {
       grid-column: span 2;
      }
     }

     &__label{
      font-size: 0.9rem;
      color: rgba($black, 60%);
      display: block;
      margin-bottom: 0.1rem;
     }

     &__value{
      font-size: 0.9rem;
      font-weight: 500;
     }
  }

  &__desc{
   background-color: $white;
   border: 1px solid $border;
   display: grid;
   overflow: hidden;

   @media (min-width: $md) {
     grid-template-columns: 1fr 1fr;
   }

   a:hover{
    text-decoration: underline;
   }

   &--agency{
    flex-grow: 1;
    @media (min-width: $xl) {
      grid-template-columns: auto 500px;
    }
    
    .details__desc__orglist__item:not(:last-of-type){
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid $border;
    }
   }

   &__map{
    background-color: #f9f9f9;
    min-height: 400px;
    .resource__map__canvas,
    .resource__map{
      width: 100%;
      height: calc(100% - 25px);
    }
   }

   &__info{
    padding: 0.75rem 0 0.75rem 1rem;
    height: 100%;
    overflow: hidden;
   }

   &__orgs{
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    max-height: 400px;
    padding-right: 0.75rem;
    @media (min-width: $md) {
      max-height: 100%;
    }
   }

   &__org{
    &:not(:last-of-type){
      margin-bottom: 2rem;
    }

    &__title{
      display: flex;
      gap: 0.5rem 1rem;
      flex-flow: column;

      @media (min-width: $md) {
        flex-wrap: nowrap;
        align-items: flex-start;
        flex-flow: row;
      }

      .btn{
        height: auto;
        margin-right: auto;
        @media (min-width: $md) {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }
    &__info{
      font-size: 0.9rem;
      margin-top: 0.3rem;
    }

    &__locations{
      margin-top: 0.5rem;

      &__title{
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    }
   }

   &__orglist{
    &__services{
      &:not(:last-of-type){
        margin-bottom: 1rem;
      }
      .details__info__title{
        flex-flow: column;
        .btn{
          margin: 0 auto 0.5rem 0;
        }

        @media (min-width: $md) {
          flex-flow: row;
          .btn{
            margin: 0 0 0 auto;
          }
        }
      }
    }
    &__item{
      position: relative;
      padding-left: 14px;
      margin-left: 10px;
      &:not(:last-of-type){
        margin-bottom: 0.75rem;
      }

      &:before{
        content: '';
        width: 5px;
        height: 5px;
        background-color: $primary;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 7px;
      }

      h6{
        font-weight: 600;
        color: $primary;
        font-size: 0.9rem;
      }
      p{
        font-size: 0.9rem;
      }
    }

    &__prog{
      margin-top: 0.75rem;
      padding-left: 1rem;
      position: relative;
      &:before{
        content: '';
        width: 5px;
        height: 5px;
        background-color: $primary;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 7px;
      }
      .details__desc__org__locations__title{
        margin-bottom: 0.2rem;
      }
      a{
        font-size: 0.9rem;
      }
    }
   }
  }
}