.contactList{
  display: flex;
  flex-flow: column;
  
  &__item{
    &:not(:last-of-type){
      border-bottom: 1px solid $border;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
  }

  .checkbox{
    gap: 1.5rem;
    margin: 0;
    flex-flow: row-reverse;

    input[type="checkbox"]{
      &:checked{
        +label{
          .contact__init{
            background-color: $success;
            color: $white;
            border-color: $success;
          }
        }
      }
    }
  }

  .contact{
    display: flex;
    gap: 1rem;

    &__init{
      width: 36px;
      height: 36px;
      min-width: 36px;
      border: 1px solid #344563;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .3s ease-in-out;
      position: relative;
      overflow: hidden;
    }

    &__details{
      
      &__info{
        display: flex;
        flex-wrap: wrap;
        flex-flow: column;
        @media (min-width: $sm) {
          flex-flow: row;
        }

        &__item{
          color: $primary;
          font-size: 0.9rem;
          @media (min-width: $sm) {
            &:not(:first-of-type){
              &:before{
                content: '|';
                margin: 0 0.5rem;
              }
            }
          }
        }

        &__link{
          font-size: 0.85rem;
        }
      }

      &__location{
        font-size: 0.85rem;
        white-space: pre-line;
      }
    }
  }
}