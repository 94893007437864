.forms {
  @extend .page;

  &__content {
    @extend .content;
    overflow-y: auto;
  }

  &__add {
    padding: 1rem;

    @media (min-width: $md) {
      padding: 1rem 1.5rem;
    }
  }

  &__filters {
    display: grid;
    gap: 10px 20px;
    @media (min-width: $sm) {
      grid-template-columns: 400px 1fr;
    }
    a {
      max-width: 140px;
      margin-left: auto;
    }
  }
  .table {
    th,
    td {
      border: 0;
      border-bottom: 1px solid $border;
      vertical-align: middle;
      min-width: 140px;
    }
    tr {
      &:last-of-type {
        td {
          border: 0;
        }
      }
    }
  }
  &__btnwrap {
    display: flex;
    gap: 0 10px;
  }
}
