.dynamic {
  @extend .content;
  height: 100%;
  &__content {
    padding: 20px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  &__wrap {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
  &__btnwrap {
    gap: 16px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (min-width: $sm) {
      display: flex;
    }
  }
  .checkbox__label {
    font-size: 14px;
  }
  &-column {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 20px;
    [class*="row-"] {
      grid-template-columns: 1fr !important;
      gap: 10px !important;
      .checkbox {
        margin: 0 !important;
      }
    }
    .radiogroup {
      flex-flow: column;
    }
    .checkbox__label {
      white-space: unset !important;
    }
  }
  .heading {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  [class*="row-"] {
    display: grid;
    gap: 20px;
  }
  .row {
    &-5 {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    &-4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    &-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &-2 {
      grid-template-columns: 1fr 1fr;
    }
    &-1 {
      .formgroup {
        max-width: 600px;
      }
    }
    &-1.row-radio {
      .formgroup {
        max-width: 100%;
      }
    }
    &-radio {
      // display: flex;
      flex-wrap: wrap;
      gap: 20px 10%;
    }
  }
  .label {
    margin-bottom: 10px;
    font-weight: 500;
  }
  .instruction {
    border: 1px solid $border;
    padding: 16px;
    border-radius: 6px;
    font-size: 14px;
    font-style: italic;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    ul > li,
    ul > li > ul > li {
      font-size: 14px;
      font-style: italic;
    }
  }
  .regular,
  .regular > ul > li,
  .regular > ul > ul > li {
    border-radius: 6px;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .radiogroup {
    margin-top: 6px;
    margin-bottom: 2rem;
    &:empty {
      display: none;
    }
    .label {
      margin: 0;
      font-weight: 400;
    }
  }
  .table {
    display: table;
    table-layout: fixed;
    padding: 10px;
    border-collapse: separate;
    margin-bottom: 16px;
    [class*="row-"] {
      display: table-row;
      border-bottom: 1px solid #ddd;
      [class*="row-"] {
        display: table-cell;
      }

      .heading {
        font-size: 16px;
        display: table-cell;
        padding: 2px 10px 10px;
        border-bottom: 1px solid $border;
      }
      .formgroup {
        font-weight: 400;
        font-size: 14px;
        display: table-cell;
        border-bottom: 1px solid $border;
        padding: 10px;
        vertical-align: baseline;
      }
      .radiogroup {
        margin-bottom: 0 !important;
        flex-flow: column;
      }
      [class*="col-"] {
        font-weight: 400;
        font-size: 14px;
        display: table-cell;
        padding: 10px;
        vertical-align: bottom;
        display: grid !important;
        grid-template-rows: repeat(auto-fit, minmax(100px, 1fr)) !important;
        gap: 20px !important;
        grid-template-columns: none;

        .formgroup {
          border-bottom: none !important;
          margin-bottom: 0 !important;
          padding: 0 !important;
        }
      }
      &:last-of-type {
        .formgroup {
          border: 0;
        }
        [class*="row-"] {
          border: 0;
        }
      }
    }
    .regular {
      border: 0;
      border-bottom: 1px solid $border;
      padding: 10px;
      border-radius: 0;
      font-style: normal;
      margin-bottom: 0px;
      background-color: transparent;
      display: table-cell;
      font-weight: bold;
    }
  }
}
