.resource {
  @extend .page;
  @media (min-width: $md) {
    overflow: hidden;
  }
  @media (max-width: ($md - 1)) {
    gap: 0;
  }
  .title--h2 {
    @media (max-width: $sm) {
      font-size: 20px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 28px;
    }
    @media (max-width: ($md - 1)) {
      font-size: 20px;
      margin-bottom: 0.25rem;
    }
  }

  &__inner {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    gap: 0;
    background-color: $white;
    border: 1px solid $border;
    @media (min-width: $md) {
      height: 100%;
      overflow: hidden;
      gap: 1rem;
    }
  }

  &__forms {
    display: flex;
    flex-flow: column;
    gap: 10px;
    margin-bottom: 1.5rem;

    &__link {
      color: $primary;
      text-decoration: underline;
      font-size: 14px;
      cursor: pointer;
      padding-left: 12px;
      position: relative;
      display: flex;
      gap: 0 10px;
      svg {
        height: 20px;
        width: auto;
        color: currentColor;
      }
      path {
        fill: currentColor;
      }

      &:hover {
        color: $success;
        path {
          fill: currentColor;
        }
      }
    }
  }

  &__filters {
    padding: 0.75rem 1rem 0;
    display: flex;
    gap: 0.5rem 0.5rem;
    align-items: flex-end;

    @media (max-width: ($md - 1)) {
      justify-content: space-between;
      padding: 0.5rem 1rem;
    }

    &__item {
      display: flex;
      flex-flow: column;
      gap: 0.5rem;
      position: relative;
      &:not(:last-of-type) {
        min-width: 25%;
      }
      &--row {
        flex-flow: row;
        width: 100%;
      }
      &--search {
        flex-grow: 1;
        min-width: 50%;
        max-width: 600px;
      }
      &--distance {
        max-width: 200px;
      }
      &--dbl {
        display: flex;
        gap: 1rem;
        @media (max-width: $sm) {
          flex-flow: column;
        }
      }
      &--state {
        flex-grow: 1;
      }
      .btn--link {
        padding: 0.25rem 1rem;
        color: $danger;
        margin-left: auto;
        text-decoration: underline;
        height: auto;
      }
      &.hideMobile {
        display: block;
        @media (max-width: ( $md - 1)) {
          display: none;
        }
      }
      &.showMobile {
        display: block;
        @media (min-width: $md) {
          display: none;
        }
      }
      .hideMobile {
        display: block;
        @media (max-width: ($md - 1)) {
          display: none;
        }
      }
      .showMobile {
        display: block;
        @media (min-width: $md) {
          display: none;
        }
      }
      .label {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
      }
      input[type="number"] {
        padding-right: 80px;
        @media (max-width: 379px) {
          padding-right: 64px;
        }
      }
      .filter_hint {
        position: absolute;
        right: 0px;
        bottom: 0;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        @extend .color--muted;
        @media (max-width: 379px) {
          padding: 0.5rem;
        }
      }
    }

    &--advanced {
      display: flex;
      flex-flow: column;
      gap: 1rem;
      padding: 1rem 0;
    }

    &--custom {
      display: flex;
      @media (min-width: $md) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: $xl) {
        grid-template-columns: repeat(4, 1fr) 100px;
      }
    }
  }

  &__results {
    flex-grow: 1;
    border-top: 1px solid $border;
    z-index: 0;
    @media (min-width: $md) {
      grid-template-columns: minmax(360px, 400px) minmax(400px, auto);
      overflow: hidden;
      display: grid;
    }

    &.map {
      grid-template-columns: minmax(360px, auto) minmax(360px, auto);
      @media (min-width: $xl) {
        grid-template-columns: minmax(360px, auto) minmax(460px, auto);
      }

      .resource__attributes {
        @media (min-width: $md) {
          display: none;
        }
      }
      .resource__map {
        display: flex;
      }
    }
  }

  &__heading {
    border-bottom: 1px solid $border;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    height: 50px;
    min-height: 50px;
    justify-content: space-between;
    @media (max-width: $xl) {
      span {
        display: none;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      @media (min-width: $xl) {
        gap: 1rem;
      }
      .btn--clear {
        padding: 0.5rem 1rem;
        font-size: 14px;
        font-weight: 400;
        align-items: center;
        color: $warning;
        gap: 3px;
        height: 36px;
        .svg-inline--fa {
          transform: translateY(-1px);
          margin-right: 0.4rem;
        }
        &:hover {
          background-color: $primary;
          color: $white;
          &:focus {
            outline-color: $primary;
          }
        }
        &:focus {
          outline-color: $warning;
        }
      }
    }
  }

  &__listing {
    flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;
    padding: 0.75rem;
    position: relative;
    @media (min-width: $sm) {
      padding: 0.75rem 1rem;
    }
  }

  &__attributes {
    height: 300px;
    display: flex;
    flex-flow: column;
    border-right: 1px solid $border;
    overflow: hidden;
    @media (min-width: $md) {
      height: 100%;
    }
  }

  &__program {
    height: 500px;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
    @media (min-width: $md) {
      border-right: 1px solid $border;
      height: 100%;
    }
    @media (max-width: $md) {
      border-top: 1px solid $border;
    }
    @media (max-width: $sm) {
      .resource__heading {
        height: auto;
        min-height: inherit;
        padding: 0.5rem 1rem 0.25rem;
      }
    }

    .resource__listing {
      padding-bottom: 0;
    }
  }

  &__paging {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $border;
    padding: 0.5rem 0.25rem;
    @media (min-width: $md) {
      padding: 0.5rem 1rem;
    }
  }

  .block {
    position: relative;
    padding: 1rem 0 1rem 1.75rem;
    border-bottom: 1px solid $success;
    @media (min-width: $sm) {
      padding: 1rem 1.5rem;
      margin-left: 0.5rem;
    }

    .checkbox {
      position: absolute;
      left: 0px;
      top: 21px;
      @media (min-width: $sm) {
        left: -10px;
      }
    }

    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
      &:before {
        height: 103.5%;
      }
    }
    @media (min-width: $sm) {
      &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        border: 0;
        background-color: $success;
        left: 0px;
        top: -10px;
      }
      &__bullet {
        height: 14px;
        width: 14px;
        border: 1px solid #2ecaa5;
        background-color: #fff;
        border-radius: 100%;
        position: absolute;
        left: -6px;
        top: 23px;

        &:after {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          background: $success;
          left: 2px;
          top: 2px;
          z-index: 2;
          border-radius: 100%;
        }
      }
    }

    &__heading {
      display: flex;
      justify-content: space-between;
      gap: 1.5rem;
      margin-bottom: 1rem;

      &__link {
        &:hover {
          text-decoration: underline;
        }
      }

      &__title {
        display: flex;
        flex-flow: column;

        small {
          color: $success;
          font-weight: 600;
          font-size: 14px;
        }
      }

      &__btns {
        display: flex;
        gap: 0.5rem 1rem;
        flex-wrap: wrap;

        .btn {
          gap: 0.5rem;
          padding: 0.25rem 1rem;

          &:hover {
            background-color: $success;
          }
        }
      }

      @media (max-width: $xxl) {
        flex-flow: column;
        gap: 0.5rem;
      }
    }

    &__description {
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }

    &__orgs {
      padding: 0;

      .block__description {
        padding-left: 1rem;
      }

      &__heading {
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
        padding-left: 1rem;
        flex-flow: column;
        padding-left: 0;

        @media (min-width: $xxl) {
          align-items: flex-end;
          flex-flow: row;
          gap: 1.5rem;
        }

        .btn {
          margin-right: auto;
          @media (min-width: $xxl) {
            margin: 0;
          }
        }

        + .block__description {
          margin-top: 0.5rem;
        }

        &__link {
          &:hover {
            text-decoration: underline;
          }
        }

        &__title {
          display: flex;
          flex-flow: column;

          small {
            color: $success;
            font-weight: 600;
            font-size: 14px;
          }
        }

        &__btns {
          display: flex;
          gap: 0.5rem 1rem;
          flex-wrap: wrap;

          .btn {
            gap: 0.5rem;
            padding: 0.25rem 1rem;
            align-items: flex-start;

            &:hover {
              background-color: $success;
            }
          }
        }
      }

      &__locations {
        padding-left: 1rem;

        &__btns {
          display: flex;
          gap: 0.5rem 1rem;
          flex-wrap: wrap;

          .btn {
            gap: 0.5rem;
            padding: 0.25rem 1rem;

            &:hover {
              background-color: $success;
            }
          }
        }

        .details__info__title {
          flex-flow: column;
          .btn {
            margin: 0 auto 0.5rem 0;
          }
          .block__orgs__locations__btns {
            margin: 0 auto 0.5rem 0;
          }
          @media (min-width: $md) {
            flex-flow: row;
            .btn {
              margin: 0 0 0 auto;
            }
            .block__orgs__locations__btns {
              margin: 0 0 0 auto;
            }
          }
        }

        > li {
          padding-left: 0.75rem;
          position: relative;
          &:before {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 4px;
            position: absolute;
            background-color: $black;
            left: 0;
            top: 10px;
          }
        }
      }

      &__distance {
        color: $success;
        font-weight: 600;
        font-size: 14px;
      }

      .title--h6 {
        color: $primary;
        margin-bottom: 0.5rem;
      }
      p,
      li {
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
      }
      strong {
        font-size: 15px;
      }
    }
  }

  &__actions {
    display: none;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1rem;
    border-top: 1px solid $border;
    gap: 0.5rem;
    flex-wrap: wrap;
    @media (min-width: $sm) {
      gap: 1rem;
    }

    &--show {
      display: flex;
    }
  }
}
.resource__results {
  .resource__map {
    display: none;
    &__canvas {
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      position: relative;
    }
    @media (max-width: $md) {
      position: fixed;
      flex-flow: column;
      left: 0;
      top: 0;
      z-index: 999;
      background-color: $white;
      padding: 0 1rem 1rem;
      width: 100%;
      height: 100vh;
      .resource__heading {
        padding: 0;
      }
    }
    .btn--cross {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 0.5rem 1rem;
      transition: color $ease-time ease-in-out;
      @media (min-width: $md) {
        display: none;
      }
      &:hover {
        color: $danger;
      }
    }
  }
  &.map {
    .resource__map {
      @media (min-width: $md) {
        display: flex;
        flex-flow: column;
        position: static;
      }
    }
  }
}
