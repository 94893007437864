.uppercase {
  text-transform: uppercase;
}
.page_content {
  padding: calc(0.75rem + 48px) 0.5rem 0.5rem;
  @media (min-width: $sm) {
    padding: calc(0.75rem + 48px) 0.75rem 0.75rem;
  }
  @media (min-width: $lg) {
    margin: 0 0 0 210px;
    padding: calc(1rem + 48px) 1.25rem 1rem;
  }
  @media (min-width: $md) {
    height: 100vh;
  }
  &--autoHeight {
    min-height: 100%;
    height: auto;
  }
}
[role="button"] {
  cursor: pointer;
}
.mt_20 {
  margin-top: 20px;
}
.mt_10 {
  margin-top: 10px;
}
.mb_20 {
  margin-bottom: 20px;
}
.mb_10 {
  margin-bottom: 10px;
}
.mb-10 {
  margin-bottom: -10px;
}
.mb_0 {
  margin-bottom: 0px !important;
}
.text__align--center {
  text-align: center;
}
.block {
  display: block;
  &--inline {
    display: inline-block;
  }
}
.asterisk {
  color: $danger;
  line-height: 16px;
}
.error {
  font-size: 0.85rem;
  color: $danger;
  margin: 0.25rem 0 0.5rem;

  &--select {
    margin: 0;
    .select__control {
      border-color: $danger !important;
    }
  }

  &--bordered {
    border-color: $danger !important;
    &:focus {
      outline-color: $danger !important;
    }
  }
}
[data-tippy-root] * {
  font-size: 12px;
  z-index: 999 !important;
}
.css-bbq5bh {
  display: flex !important;
}
.css-17oi9u9-Bubble {
  display: flex;
  border-radius: 4px !important;
  padding: 10px !important;
  display: grid;
  grid-template-columns: minmax(260px, auto);
}
.formgroup {
  .css-5a7vsu-container {
    overflow: visible;
  }
  .css-1bx7l6n-control {
    background-color: $white !important;
    min-height: 44px !important;
    border-radius: 0.25rem;
    padding: 0 0.5rem !important;
    border: 1px solid $border !important;
    box-shadow: 0px 0px 0px 1px transparent;
    transition: all 300ms ease-in-out !important;
    .css-1cgsk15-placeholder {
      color: $black !important;
    }
    .css-1bhtqla-indicatorContainer {
      background-image: url("../../Assets/images/select-indicator.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0.9rem;
      width: 24px;
      height: 24px;
      span {
        display: none !important;
      }
    }
  }
  .css-11v79b0-control {
    border: 1.25px solid $primary !important;
    min-height: 44px !important;
    padding: 0 0.5rem !important;
    transition: all $ease-time ease-in-out;
    .css-1bhtqla-indicatorContainer {
      background-image: url("../../Assets/images/select-indicator.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0.9rem;
      width: 24px;
      height: 24px;
      span {
        display: none !important;
      }
    }
  }
  .css-tj5bde-Svg {
    fill: #999999;
  }
  .select__control {
    min-height: 44px !important;
    border-color: $border;
    cursor: pointer;
    border-radius: 0.25rem;

    &:hover {
      border-color: $border;
    }
    &:focus,
    &--is-focused {
      color: $black;
      border-color: $primary !important;
      outline: 0px solid $primary !important;
      box-shadow: 0 0 0 0px $primary !important;
      &:hover {
        border-color: $primary !important;
      }
    }
  }
  .select__error__control {
    min-height: 44px;
    cursor: pointer;
    border-radius: 0.25rem;
    border-color: $danger !important;
    outline: 1px solid $danger !important;
    margin: 0;
    &:focus {
      border-color: $danger !important;
      outline: 1px solid $danger !important;
      box-shadow: none;
    }
    &:hover {
      border-color: $danger !important;
      outline: 1px solid $danger !important;
      box-shadow: none;
    }
  }
}
.btnWrap {
  display: flex;
  margin-top: 0.5rem;
  gap: 0.75rem;
  @media (max-width: $md) {
    padding-bottom: 1rem;
  }

  &--wrap {
    flex-wrap: wrap;
    .btn {
      @media (max-width: $sm) {
        flex-grow: 1;
      }
    }
  }
}
.page {
  display: flex;
  gap: 0.5rem;
  flex-flow: column;
  @media (min-width: $md) {
    height: 100%;
  }
}
.content {
  flex-grow: 1;
  background-color: $white;
  border: 1px solid $border;
  @media (min-width: $md) {
    overflow: hidden;
  }
}
input[type="file"],
input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
  height: 44px;
}
iframe[style^="position"] {
  display: none !important;
}
.tooltip-inner {
  background-color: lighten($black, 30%);
  box-shadow: 0px 3px 20px rgba($black, 15%);
  color: $white;
  font-size: 12px;
  border-radius: 5px;
  padding: 8px 14px;
}
.flex {
  display: flex;
  &--wrap {
    flex-wrap: wrap;
  }
}
.alignitems {
  &--center {
    align-items: center;
  }
  &--end {
    align-items: center;
    @media (min-width: $xl) {
      align-items: flex-end;
    }
  }
}
.justify {
  &--center {
    justify-content: center;
  }
}
.bs-tooltip-top {
  z-index: 999;
  max-width: 340px;
}
.heading__action {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.mode {
  margin-bottom: -1rem;
  padding: 0 0.75rem;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  @media (min-width: $md) {
    padding: 0 1.5rem;
  }

  &--view {
    [class^="col_"] {
      padding-bottom: 1rem;
    }
    .formgroup {
      border-bottom: 1px solid $border;
      height: 100%;
    }

    label {
      color: rgba($black, 6%);
    }

    .value {
      font-weight: 600;
      &--tags {
        display: -webkit-box;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        -webkit-box-align: start;
        align-items: flex-start;
      }
      &__tag {
        background-color: #f5f5f5;
        border: 1px solid #ddd;
        border-radius: 6px;
        font-size: 14px;
        padding: 8px 1rem;
      }

      li {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        padding-left: 1.5rem;

        &:before {
          content: "";
          border: 0px;
          width: 6px;
          height: 6px;
          transform: rotate(45deg);
          background-color: #000;
          position: absolute;
          left: 5px;
          top: 7px;
        }
        &:not(:last-of-type) {
          margin-bottom: 0.5rem;
        }
        p {
          margin-bottom: 5px;
        }
        small {
          color: rgba(0, 0, 0, 0.7);
          font-weight: 600;
          display: block;
          font-size: 14px;
          margin: 0px 0px 2px;
          font-style: italic;
        }
      }
    }
  }

  &--edit {
    label.label {
      margin-bottom: 0.25rem;
    }
  }
}
#profilePicModal {
  .modal__dialog--centered {
    max-width: 330px;
  }
  .modal__body {
    padding: 0 1.5rem 1rem;
  }
}
.hide {
  &--md {
    @media (max-width: $md) {
      display: none;
    }
  }
  &--lg {
    @media (max-width: $lg) {
      display: none;
    }
  }
  &--xl {
    @media (max-width: $xl) {
      display: none;
    }
  }
  &--xxl {
    @media (max-width: $xxl) {
      display: none;
    }
  }
}
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fade {
  opacity: 0;
  transition: opacity $ease-time * 2 ease-in-out;
  &.show {
    opacity: 1;
  }
}
.select__control {
  min-height: 44px !important;
  border-color: $border !important;
  &:hover {
    border-color: $border;
  }
  &:focus,
  &--is-focused,
  &--menu-is-open {
    color: $black;
    border-color: $primary !important;
    outline: 12px solid $primary !important;
    box-shadow: 0 0 0 0px $primary !important;
    &:hover {
      border-color: $primary !important;
    }
  }
  &.warning {
    border-color: $danger !important;
    .select__placeholder {
      color: $danger;
    }
  }
}
.select__option {
  display: flex !important;
  gap: 0.5rem;
  cursor: pointer !important;
  text-transform: capitalize;
  align-items: center;

  .btn {
    justify-content: flex-end;
  }

  &--is-selected {
    background-color: rgba($success, 20%) !important;
    color: $black !important;
  }

  &--is-focused {
    background-color: rgba($success, 10%) !important;
    color: $black !important;
  }
  label {
    font-size: 0.875rem;
  }
}
.css-319lph-ValueContainer {
  padding: 2px 0.5rem 2px 1rem !important;
}
.nopadding {
  padding: 0 !important;
}
.no_data {
  height: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  font-size: 18px !important;
  color: rgb(0 0 0 / 45%) !important;
  // font-weight: 600 !important;
  border: none !important;
  &--cell {
    display: table-cell !important;
  }
}
.alert {
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  margin-bottom: 1rem;

  &__danger {
    color: #eb405e;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }

  &__custom {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 16px;
    padding: 10px 15px;
    line-height: 16px;
  }

  .btn {
    color: #000;
    height: auto;
  }
}
.select__multi-value__remove {
  margin-left: 6px;
  &:hover {
    background-color: $danger !important;
    svg {
      path {
        fill: $white !important;
      }
    }
  }
}
.css-1pahdxg-control {
  outline: 1px solid transparent !important;
}
.select__loading-indicator {
  gap: 0 0.25rem;
  span {
    border-radius: 0 !important;
    width: 4px !important;
    height: 12px !important;
    margin: 0 !important;
    background-color: $primary;
  }
}
.select__menu-list {
  font-size: 0.875rem !important;
}
.select__placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mw150 {
  min-width: 150px;
}
