.createRef {
  @extend .page;

  &__content {
    @extend .content;
    padding: 0.5rem 0.75rem;
    overflow-y: auto;
    display: flex;
    flex-flow: column;
    flex-flow: 1;
    @media (min-width: $md) {
      padding: 1rem 1.5rem;
    }
  }

  &__steps {
    margin-bottom: 0.5rem;
  }

  &__attrs {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-top: 0.5rem;

    &__item {
      border: 1px solid $border;
      padding: 0.3rem 0.75rem;
      display: flex;
      align-items: center;
      font-size: 0.9rem;
    }
  }
  .ql-mention-list-container {
    width: 100%;
    max-width: 380px;
  }
  .ql-mention-list {
    max-height: 300px;
    overflow-y: auto;
    .ql-mention-list-item {
      padding: 10px 1rem;

      &:not(:last-of-type) {
        border-bottom: 1px solid $border;
      }
    }
    .suggestions {
      .container {
        padding: 0;
      }
      .title {
        font-size: 13px;
        font-weight: bold;
      }
      .tag {
        font-size: 13px;
        color: rgba($black, 60%);
      }
    }
  }
  &__editor {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    flex-grow: 1;

    &__quill {
      flex-grow: 1;
      display: flex;
      flex-flow: column;

      .quill {
        flex-grow: 1;
      }
    }
  }
}
