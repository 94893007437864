.tree{
  display: none;

  &.show{
    display: block;
  }

  &__plus,
  &__minus{
    font-size: 1.25rem;
    color: $primary;
  }
  
  &__haschild{
    >.checkbox{
      >.trigger{
        position: absolute;
        right: 0;
        top: 0;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        .tree__plus{
          display: block;
        }
        .tree__minus{
          display: none;
        }
      }
    }
  }
  
  &__expanded{
    >.checkbox{
      >.trigger{
        .tree__plus{
          display: none;
        }
        .tree__minus{
          display: block;
        }
      }
    }
  }

  &__link{
    padding: 0.5rem 0;
    position: relative;
    .tree{
      margin: 0.5rem 0 0rem 0.5rem;
      @media (min-width: $sm) {
        margin: 0.5rem 0 0rem 1.5rem;
      }
    }
  }

  .checkbox{
    margin-bottom: 0;
    cursor: pointer;
    position: relative;
    z-index: 5;
    gap: 0.5rem;
    padding-right: 30px;

    &__label{
      overflow: unset;
      white-space: unset;
    }

    input[type=checkbox]{
      +label{
        font-size: 0.9rem;
        line-height: 18px;
        color: $black;
        word-break: break-word;
      }
      &:checked{
        +label{
          font-weight: 400;
        }
      }
    }
  }

  &--withlines{
    .tree__link{
     
      &:after, &:before {
        content: "";
        position: absolute;
        background-color: $success;
        transition: all .2s ease-in-out;
        z-index: 1;
      }
      &:after {
        width: 20px;
        height: 1px;
        top: 17px;
        left: -18px;
      }
      &:before {
        width: 1px;
        height: 100%;
        top: 1.5rem;
        left: 8.5px;
      }
      &:last-of-type{
        &:before{
          display: none;
        }
      }
    }
    &.attributes{
      >.tree__link{
        &:before {
          width: 1px;
          height: 100%;
          top: 1.5rem;
          left: 8.5px;
        }
        &:after {
          width: 20px;
          height: 1px;
          top: 17px;
          left: 12px;
        }
        &>li:after {
          content: "";
          width: 16px!important;
          left: 14px!important;
        }
      }
    }
  }
}