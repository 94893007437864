.addlocation {
  @extend .page;

  &__content {
    @extend .content;
  }

  .mode {
    &--edit {
      padding: 1rem 0.75rem 0.35rem;
      @media (min-width: $sm) {
        padding: 1rem 1.5rem 0;
      }
    }
  }

  .formgroup {
    .table {
      tbody > tr > td:nth-child(1) {
        max-width: 20rem;
      }
      tbody > tr > td:nth-child(2),
      tbody > tr > td:nth-child(3) {
        max-width: 35rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .dropdown {
    width: 100%;
    padding: 1rem;
    max-height: 360px;
    display: flex;
    gap: 0.75rem;
    flex-flow: column;
    @media (min-width: $sm) {
      overflow-y: auto;
    }
    td,
    th {
      font-size: 14px;
      padding: 0.5rem 0.75rem;
      max-width: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (min-width: $sm) {
        padding: 1rem;
      }
    }

    .table {
      tbody {
        tr {
          cursor: pointer;
        }
      }
      @media (max-width: $sm) {
        flex-grow: 1;
        overflow-y: auto;
        display: block;
        thead {
          display: none;
        }
        tbody {
          display: block;
        }
        tr {
          display: flex;
          flex-flow: column;
          padding: 0.5rem 1rem;
          &:not(:last-of-type) {
            border-bottom: 1px solid $border;
          }
        }
        td {
          border: 0px;
          padding: 0px;
          &:first-child {
            font-weight: bold;
          }
          &:last-child {
            padding-top: 0.5rem;
          }
        }
      }
    }
  }
}
