.org {
  @extend .page;

  &__content {
    padding: 1rem 0;
    @extend .content;
  }
}
.org__flex{
  display: flex;
  gap: 5px;

  .btn{
    &:focus{
      outline: 0;
    }
    svg{
      height: 18px;
      width: auto;
      transition: none;
    }
    path{
      fill: currentColor;
      transition: none;
    }
  }
}