.permissions{
  gap: 0.5rem 0;
  @extend .page;
  &__content{
    @extend .content;
    overflow-y: auto;
    padding: 10px;

    @media (min-width: $md) {
      padding: 20px;
    }
  }
  &__table{
    display: flex;
    flex-flow: column;
    gap: 0px ;
    &:not(:last-of-type){
      margin-bottom: 30px;
    }
  }
  &__module{
    display: grid;
    padding: 0 0 10px 0;
    gap: 10px;

    @media (min-width: $sm) {
      grid-template-columns: 1fr auto;
    }

    &__title{
      font-size: 20px;
      font-weight: bold;
      color: $primary;
      sup{
        font-size: small;
      }
    }
    &__action{
      align-self: center;
    }
  }
  &__row{
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 0 10px;
    border-bottom: 1px solid $border;
    @media (min-width: $sm) {
      grid-template-columns: repeat(5, auto);
    }
    @media (min-width: $lg) {
      gap: 0 40px;
      grid-template-columns: 1fr repeat(5, auto);
    }
  }
  &__cell{
    padding: 10px 0;
    &:first-of-type{
      grid-column: span 3;

      @media (min-width: $sm) {
        grid-column: span 5;
      }

      @media (min-width: $lg) {
        grid-column: span 1;
      }
    }
  }
}