.breadcrumb{
  display: block;

  ul, ol{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;

    li{
      font-size: 0.9rem;

      &:not(:first-of-type){
        &:before{
          content: '/';
          font-size: 0.7rem;
          padding: 0 0.35rem 0 0.25rem;
        }
      }

      a, span{
        font-size: 0.9rem;
      }
      a{
        color: $black;
        &:hover{
          text-decoration: underline;
          color: $primary;
        }
      }
      span{
        color: $primary;
      }
    }
  }
}