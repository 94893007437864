.careteams{
  @extend .page;

  &__list{
    height: 100%;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    height: auto;

    &__item{
      background-color: $white;
      padding: 1rem;
      @media (min-width: $md) {
        padding: 1.5rem;
      }
    }

    &__header{
      display: grid;
      gap: 1rem;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid $border;

      @media (min-width: $sm) {
        grid-template-columns: 1fr auto;
        gap: 0 1rem;
      }

      h2{
        line-height: 1.1;
        margin-bottom: 0.25rem;
      }
      small{
        line-height: 1.3;
        display: block;
        color: rgba($black, 60%);
      }
    }

    &__grid{
      display: grid;
      gap: 1rem;

      @media (min-width: $md) {
        grid-template-columns: 1fr 1fr;
      }
      
      label{
        color: rgba($black, 60%);
      }
      .careteams__list__field{
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
  .reflist__details__body{
    overflow: hidden;
    overflow-y: auto;
  }
  .reflist__details{
    overflow: hidden;
  }

  .reflist__content{
    overflow: hidden;
  }
  .reflist__details__header{
    flex-wrap: wrap;

    @media (min-width: $lg) {
      flex-wrap: nowrap;
    }
  }

  .reflist__table__row{
    &:not(:last-of-type){
      border-bottom: 1px solid $border;
    }
    &:not(.reflist__table__row--full){
      grid-template-columns:  1fr !important;
      @media (min-width: $md) {
        grid-template-columns: repeat(3, 1fr) !important;
      }
      @media (min-width: $xl) {
        grid-template-columns: repeat(3, 1fr) !important;
      }
    }
    &--full{
      display: grid;
      grid-template-columns: 1fr;
      gap: 0;
      @media (min-width: $md) {
        grid-template-columns: 1fr auto;
        gap: 0 1rem;
      }
    }
  }
  .reflist__table__cell{
    &--priority{
      flex-flow: row !important;
      gap: 0 0.5rem !important;
      @media (min-width: $md) {
        display: block;
      }
    }
    &:last-of-type{
      text-align: left;
    }
  }
  .reflist__filters{
    gap: 0.5rem 1rem;
  }

  .reflist__listing {
    &.active{
      display: grid;
      grid-template-columns: minmax(50%,1fr) minmax(auto, calc(50% - 1rem));

      @media (min-width: $lg) {
        grid-template-columns: 1fr minmax(50%, 1fr);
      }

      @media (min-width: $xxl) {
        grid-template-columns: minmax(50%, 2fr) 1fr;
      }
      .reflist__details{
        max-width: inherit;
      }
      .reflist__table__row{
        &:not(.reflist__table__row--full){
          grid-template-columns:  1fr !important;
          @media (min-width: $lg) {
            grid-template-columns: repeat(1, 1fr) !important;
          }
          @media (min-width: $xl) {
            grid-template-columns: repeat(2, 1fr) !important;
          }
          @media (min-width: $xxl) {
            grid-template-columns: repeat(3, 1fr) !important;
          }
        }
      }
    }
  }
}