$modal-radius: 0.3rem !default;
$modal-padding: 1rem !default;

.modal-open {
  overflow: hidden;
  .modal {
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1072;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: transparent;
  transition: all $ease-time ease-in-out;

  &__dialog {
    position: relative;
    width: auto;
    visibility: hidden;
    opacity: 0;
    margin: 0.5rem auto;
    pointer-events: none;
    transition: all $ease-time ease-out;
    transition-delay: 300ms;
    transform: translate(0, -25%);
    @media (min-width: $sm) {
      max-width: 500px;
      margin: 1.75rem auto;
    }

    &--centered {
      display: flex;
      align-items: center;
      min-height: 100%;
      margin: 0;
      @media (min-width: $sm) {
        margin: 0.5rem auto;
        min-height: calc(100% - (1.75rem * 2));
      }
      .modal__content {
        height: auto;
      }
    }

    &--sidebar {
      margin: 0;
      float: right;
      transform: translate(100%, 0);
      width: 100%;
      max-width: 560px;
      border-radius: 0;
      height: 100%;
      display: flex;
      flex-flow: column;

      .modal__content {
        height: 100%;
        border-radius: 0;
      }
      .modal__body {
        overflow-y: auto;
      }
      .modal__body--bordered {
        border-bottom: 1px solid $border;
      }
      .modal__footer--bordered {
        border-top: 1px solid $border;
      }
    }

    &--bordered {
      .modal__body {
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
      }
    }

    &--xs {
      max-width: 300px;
    }

    &--sm {
      max-width: $sm;
    }

    &--md {
      max-width: $md;
    }

    &--lg {
      max-width: $lg;
    }

    &--xl {
      max-width: $xl;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: $white;
    background-clip: padding-box;
    border-radius: $modal-radius;
    outline: 0;
    box-shadow: 0 0 20px rgba($black, 50%);
    background-color: $white;

    @media (max-width: $md) {
      border-radius: 0;
      height: 100vh;
    }
  }

  &__title {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__header {
    padding: 1rem 1.5rem;
    // padding: $modal-padding $modal-padding * 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid #ddd;

    @media (max-width: $sm) {
      padding: $modal-padding;
    }

    .btn {
      margin: 0 -1.25rem 0 0;
      &:hover {
        background: none;
        .icon {
          color: rgba($black, 100%);
          transform: scale(0.8);
        }
      }
      &:focus {
        outline: 0;
      }
    }
  }

  &__body {
    padding: $modal-padding * 1.5;
    flex-grow: 1;
    @media (max-width: $sm) {
      padding: 0 $modal-padding;
      overflow-y: auto;
      overflow-x: hidden;
    }
    &__content {
      padding: 10px 20px;
      font-size: 16px;
    }

    .image__upload {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        width: 100% !important;
        > div {
          width: 100% !important;
          label {
            width: 100%;
          }
        }
      }
    }

    .card {
      margin-bottom: 1rem;
      padding: 1.5rem;
    }

    .text {
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    .pwd {
      position: absolute;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      height: 100%;
      right: 0;
      top: 0;
      opacity: 0.5;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 300ms ease-in-out;
    }
  }

  &__footer {
    display: flex;
    padding: $modal-padding $modal-padding * 1.5;
    @media (max-width: $sm) {
      padding: $modal-padding;
      border-top: 1px solid $border;
    }

    .btn {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &--show {
    background-color: rgba($black, 50%);
    opacity: 1;
    visibility: visible;

    .modal__dialog {
      transform: translate(0, 0);
      opacity: 1;
      visibility: visible;
      pointer-events: unset;
    }
  }
}
