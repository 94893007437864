.goals{
  gap: 0.25rem;
  
  &.reflist .table--striped td{
    &:first-child{
      width: 170px;
    }
  }

  .reflist__filters{
    align-items: flex-end;
    padding-bottom: 0.5rem;
    flex-wrap: wrap;
    gap: 0.25rem 1rem;
    @media (max-width: $md) {
      gap: 0.5rem 1rem;
    }
    &__item{
      flex-grow: 1;
      @media (min-width: $xl) {
        width: 33.33%;
      }
      @media (min-width: $md) {
        width: calc(33.33% - 1rem);
      }
      @media (max-width: $md) {
        width: calc(50% - 1rem);
      }
      @media (max-width: 479px) {
        width: 100%;
        &:nth-last-child(2){
          width: 50%;
        }
      }
    }
    @media (min-width: $xl) {
      flex-wrap: nowrap;
    }
  }

  .reflist__table__row{
    &:not(.reflist__table__row--full){
      @media (min-width: $sm) {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem !important;
      }
      @media (min-width: $md) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  .active{
    .reflist__table__row{
      &:not(.reflist__table__row--full){
        // @media (min-width: $sm) {
        //   grid-template-columns: repeat(2, 1fr);
        //   gap: 1rem !important;
        // }
        // @media (min-width: $lg) {
        //   grid-template-columns: repeat(2, 1fr);
        //   gap: 1rem !important;
        // }
        @media (max-width: $xl){
          grid-template-columns: 1fr;
        }
        @media (max-width: $xxl) and (min-width: $xl) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  .reflist__table__cell--priority{
    @media (min-width: $sm) {
      text-align: right !important;
    }
  }

  &__content{
    @extend .content;
    background-color: transparent;
    border: 0;
  }

  &__paging{
    padding-top: 0.5rem;
  }

  &__listing{
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
    height: 100%;
    width: 100%;
  }

  .reflist__listing{
    height: calc(100% - (185px + 1rem));
    @media (min-width: $xl) {
      height: calc(100% - (107px + 1rem));
    }
  }

  .reflist__table{
    &__row{
      flex-wrap: wrap;
      @media (min-width: $md) {
        flex-wrap: unset;
      }
      &:not(.reflist__table__row--full){
        flex-flow: column;
        gap: 0.1rem 1rem;
        @media (min-width: $md) {
          flex-flow: row;
        }
      }
    }
    &__cell{
      &:last-of-type{
        flex-flow: column;
        gap: 0;
      }
    }
  }

  .active{
    .reflist__table{
      &__row{
        &:not(.reflist__table__row--full){
          @media (max-width: $xxl) {
            flex-flow: column;
          }
        }
      }
      &__cell{
        &:last-child{
          text-align: unset;
        }
      }
    }
  }
}

.addGoal{
  @extend .page;

  &__content{
    @extend .content;
    padding: 1rem 0.75rem;
    overflow-y: auto;
    @media (min-width: $md) {
      padding: 1rem 1.5rem;
    }
  }
}
