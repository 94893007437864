$nav-background: $white !default;
$nav-active-background: $primary !default;
$nav-radius: 0.25rem !default;
$nav-padding: 0.5rem 1.5rem !default;
$nav-border: $border !default;

.nav {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;

  &--right {
    margin-left: auto;
  }

  &--icon {
    vertical-align: -0.1rem;
    margin-right: 0.5rem;
  }

  &__item {
    display: block;
    position: relative;

    &:hover,
    &:focus {
      .dropdown {
        opacity: 1;
        visibility: visible;
        top: 100%;
        pointer-events: unset;
      }
    }
  }

  &__link {
    display: block;
    padding: 0.75rem 1rem;
    text-align: center;
    color: set-color($nav-background);
    cursor: pointer;
    background: none;

    &--nolink{
      cursor: text;
    }

    &:hover {
      color: $primary;
    }

    &--active {
      color: $primary;
    }

    // Disabled nav link
    &--disabled {
      opacity: 0.4;
    }
  }

  &__divider {
    margin: 1rem 0;
    background: $border;
    height: 1px;
    padding: 0;
    width: 100%;
  }

  // Now wrap content
  &--nowrap {
    flex-wrap: nowrap;
  }

  // Align nav items stacked
  &--column {
    flex-direction: column;
    .nav__item{
      width: 100%;
    }
    .nav__link {
      text-align: left;
      width: 100%;
    }
  }

  // Align nav item to center
  &--justify-center {
    justify-content: center;
  }

  // Align nav item to right
  &--justify-end {
    justify-content: flex-end;
  }

  // Align items vertically center
  &--align-center {
    align-items: center;
  }

  // Pills style nav
  &--pills {
    .nav__link {
      border-radius: $nav-radius;

      &.nav__link--active {
        background: $primary;
        color: set-color($primary);
      }
    }
  }

  // Strentched nav item to fill
  &--fill {
    .nav__item {
      flex: 1 1 auto;
    }
  }

  // Tabs style nav
  &--tabs {
    border-bottom: 1px solid $border;

    &.nav {
      .nav__item {
        margin-bottom: -1px;
      }

      .nav__link {
        color: set-color($nav-background);
        border-radius: $nav-radius $nav-radius 0 0;
        border: 1px solid $border;

        &:hover,
        &:focus {
          border: 1px solid rgba($nav-border, 50%);
        }

        .nav__link--active {
          background: $white;
          border: 1px solid $nav-border;
          border-bottom-color: transparent;
          color: $primary;
        }
      }
    }
    &:not(&--custom) {
      border-bottom: 1px solid $border;
      display: flex;
      margin-bottom: 30px;
    }
    &--custom {
      display: flex;

      .nav__item {
        max-width: 400px;
        border: 1px solid $border;
      }
      .active {
        box-shadow: inset 0 3px 5px rgba($black, 13%);
      }
    }
  }
}

.tabcontent {
  .tab__pan {
    display: none;
    opacity: 0;
    transition: opacity $ease-time ease-in-out;
    &.active {
      display: block;
    }
    &.show {
      opacity: 1;
    }
  }
}
