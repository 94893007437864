.reflist {
  @extend .page;
  gap: 0.5rem;
  @media (max-width: $md) {
    height: auto;
    padding-bottom: 0.75rem;
  }

  &__filters {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    @media (min-width: $md) {
      flex-wrap: nowrap;
    }
    @media (max-width: $md) {
      gap: 0.75rem;
    }

    &__item {
      position: relative;
      @media (min-width: $md) {
        width: 25%;
        flex-wrap: nowrap !important;
      }
      @media (min-width: $sm) {
        width: calc(50% - calc(0.75rem / 2));
      }
      &--grow {
        flex-grow: 1;
      }
      &:last-of-type {
        width: auto !important;
      }
      &:nth-child(1),
      &:nth-child(2) {
        width: 30%;
      }
      &--sort {
        margin-left: auto;
        flex-grow: unset !important;
        &--show {
          .dropdown {
            opacity: 1;
            visibility: visible;
            top: 100%;
            pointer-events: unset;
          }
        }
        .btn--white {
          position: relative;
          padding-right: 2.5rem;

          &:before {
            content: url("../../Assets/images/asc.svg");
            position: absolute;
            right: 16px;
            top: 13px;
          }

          &:after {
            content: url("../../Assets/images/desc.svg");
            position: absolute;
            right: 24px;
            top: 13px;
          }
        }
        .dropdown {
          left: inherit;
          right: 0;
        }
        .nav {
          &__link {
            white-space: nowrap;
            font-size: 14px;
            padding: 0.35rem 2rem 0.25rem 1rem;
            opacity: 0.7;
            &--asc {
              background: url("../../Assets/images/asc.svg") right 12px center no-repeat;
              opacity: 1;
              font-weight: 600;
            }
            &--desc {
              background: url("../../Assets/images/desc.svg") right 12px center no-repeat;
              opacity: 1;
              font-weight: 600;
            }
            &:not(:last-of-type) {
              border-bottom: 1px solid $border;
            }
          }
        }
      }
    }
  }

  &__listing {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-grow: 1;
    height: calc(100% - calc(80px + 1rem));
    @media (max-width: $md) {
      height: auto;
    }
  }

  &__content {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__table {
    gap: 1rem;
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 2px 0rem 2px 2px;
    @media (min-width: $md) {
      flex-grow: 1;

      .active & {
        padding: 2px 0.5rem 2px 2px;
      }
    }
    @media (max-width: $md) {
      height: auto;
      width: 100%;
    }

    &__card {
      display: flex;
      flex-flow: column;
      background-color: $white;
      border: 1px solid $border;
      border-radius: 0.25rem;
      position: relative;
      outline: 2px solid transparent;
      &:after {
        content: "";
        border: 10px solid transparent;
        border-left-color: $success;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(80%, -50%);
        opacity: 0;
        visibility: hidden;
        @media (max-width: $md) {
          display: none;
        }
      }

      &--active {
        outline: 2px solid $success;
        &:after {
          content: "";
          opacity: 1;
          transform: translate(100%, -50%);
          visibility: visible;
        }
      }
    }

    &__row {
      display: grid;
      grid-template-columns: 50% 1fr auto;
      gap: 0.5rem 1rem;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      @media (min-width: $md) {
        gap: 1rem;
      }
      @media (max-width: $xl) and (min-width: $md) {
        grid-template-columns: 1fr;
        gap: 0.25rem;
      }

      @media (max-width: $sm) {
        grid-template-columns: 1fr;
        gap: 0.25rem;
      }

      &--full {
        background-color: #f5f5f5;
        align-items: center;
        @media (min-width: $sm) {
          grid-template-columns: 1fr 1fr;
        }
      }
      p,
      strong,
      label,
      span {
        font-size: 14px;
        margin: 0;
        line-height: 1.5;
      }
    }

    &__cell {
      &--name {
        strong {
          display: block;
          font-size: 1rem;
          line-height: 1.2;
          color: $primary;
        }
        span {
          line-height: 1;
          color: $primary;
        }
      }
      span[role="button"] {
        color: $primary;
        text-decoration: underline;
      }
      &:last-of-type {
        gap: 0.5rem;
        display: flex;
        @media (min-width: ($xl + 1)) {
          text-align: right;
          display: block;
        }
      }
    }
  }

  &__list {
    width: 100%;
  }

  &__details {
    width: 100%;
    height: 100%;
    background-color: $white;
    border: 1px solid $border;
    display: flex;
    flex-flow: column;
    transition: all $ease-time ease-in-out;
    display: none;
    .active & {
      display: flex;
    }

    @media (min-width: $xl) {
      max-width: 650px;
    }
    @media (max-width: $md) {
      position: fixed;
      width: 100%;
      height: 100vh;
      left: 0;
      top: 0;
      z-index: 999;
      opacity: 0;
      visibility: hidden;
      transform: translateY(20px);
      &--show {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
      }
    }

    &__header {
      display: flex;
      gap: 1rem;
      border-bottom: 1px solid $border;
      padding: 1rem;

      &__title {
        flex-grow: 1;
        span {
          font-size: 0.8rem;
          display: block;
          margin-top: -0.1rem;
        }
      }

      &__actions {
        display: flex;
        gap: 0.75rem;

        .btn {
          padding: 0 0.75rem;
          height: 34px;
          .svg-inline--fa {
            font-size: 14px;
          }
          &.close_icon {
            @media (min-width: $md) {
              display: none;
            }
          }
        }
      }
    }

    &__body {
      height: 100%;
      overflow-y: auto;
      padding: 1rem;

      h5 {
        margin-bottom: 0.5rem;
      }
      p,
      label,
      strong,
      span {
        font-size: 0.85rem;
        line-height: 1.6;
      }
    }

    &__address {
      display: flex;
      gap: 1rem;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid $border;

      &__cell {
        &:last-of-type {
          label {
            margin-bottom: 0;
          }
        }
      }
    }

    &__status {
      gap: 1rem;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid $border;
      &__cell {
        padding-bottom: 1rem;
        label {
          font-size: 1rem;
          font-weight: 600;
          color: #000;
        }
      }
    }

    &__refdetails {
      display: flex;
      flex-flow: column;
      gap: 0.5rem;
    }

    &__block {
      &:not(:last-of-type) {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $border;
      }
    }

    &__flex {
      display: flex;
      @media (min-width: $xl) {
        gap: 1rem;
      }
      @media (max-width: $xl) {
        flex-flow: column;
      }

      &__label {
        font-size: 0.85rem;

        @media (min-width: $xl) {
          min-width: 50%;
          width: 50%;
          text-align: right;
        }
        @media (min-width: $xxl) {
          min-width: 250px;
          width: 250px;
        }
      }

      &__value {
        font-size: 0.85rem;
        font-weight: 600;
      }

      &__title {
        flex-grow: 1;
        margin: 8px 0;
      }
    }
  }

  .table {
    &--striped {
      border: 0;
      td {
        border: 0;
        font-size: 0.85rem;
        padding: 0.5rem 1rem;
        &:first-child {
          width: 120px;
        }
      }

      &__edit {
        tr {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          flex-flow: row;
        }
        td {
          border: 0;
          font-size: 0.85rem;
          padding: 0.5rem 1rem;
          &:first-child {
            display: flex;
            gap: 3px 10px;
            padding: 10px 15px 15px;
            -webkit-box-flex: 1;
            flex-grow: 1;
            flex-wrap: wrap;
            .formgroup {
              width: calc(50% - 5px);
              &:last-child {
                width: 100%;
              }
            }
          }
        }
      }
      @media (max-width: $xl) {
        display: block;
        tbody {
          display: block;
        }
        tr {
          display: block;
          padding: 1rem 0;
        }
        td {
          display: block;
          padding: 0.2rem 1rem;
          width: 100% !important;
        }
      }
    }
  }
}
