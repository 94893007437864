.sidebar {
  background: $primary;
  position: fixed;
  left: 0;
  top: 48px;
  bottom: 0;
  width: 210px;
  overflow-y: auto;
  padding: 1rem 0 0.25rem;
  display: flex;
  flex-flow: column;
  transition: transform $ease-time ease-in-out;
  transform: translateX(-110%);
  z-index: 98;

  @media (min-width: $lg) {
    transform: translateX(0%);
  }

  .sidebar__visible & {
    transform: translateX(0%);
  }

  .patient {
    display: block;
    text-align: center;
    padding: 0 1rem;
    &__profile {
      img {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        border: 2px solid $white;
        margin: 0 auto 0.5rem;
      }
      span {
        border: 3px solid $white;
        display: block;
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-weight: 600;
        color: $white;
        border-radius: 100%;
        outline: 0px solid transparent;
        transition: all 300ms ease-in-out;
        font-size: 2rem;
        margin: 0 auto 0.5rem;
        text-transform: uppercase;
      }
    }
    &__name {
      color: $white;
      font-size: 1rem;
      font-weight: 600;
      text-transform: capitalize;

      em {
        display: block;
        font-weight: 400;
        font-size: 0.8rem;
      }
    }
    &__dob {
      color: $white;
      font-size: 14px;
    }
    &__add {
      margin: 0.5rem 0;
      color: $white;
      font-size: 14px;
    }
    &__link {
      color: $yellow;
      font-size: 14px;
      margin-top: 0.5rem;
      text-decoration: underline;
      transition: color $ease-time ease-in-out;
      cursor: pointer;
      display: inline-block;
      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }

  .nav {
    margin: 1rem 0;
    border-bottom: 1px solid rgba($white, 20%);

    &--column {
      .nav__item {
        gap: 0 !important;
      }
    }

    .nav__item {
      white-space: nowrap;
      color: $white;
      cursor: pointer;
      padding: 0;
      font-size: 14px;
      white-space: nowrap;
      color: $white;
      display: flex;
      flex-flow: column;
      gap: 0.8rem;
      font-weight: 600;
      sup {
        font-size: 50%;
        color: $white;
        margin-left: 0.2rem;
      }
    }
    .nav__link {
      border-top: 1px solid rgba($white, 20%);
      white-space: nowrap;
      color: $white;
      padding: 0.75rem;
      font-weight: 400;
      font-size: 1rem;
      transition: opacity $ease-time ease-in-out;
      display: flex;
      gap: 0.5rem;
      // opacity: 0.7;

      .blueIcon {
        display: none;
      }

      &--active {
        opacity: 1;
        background-color: $white;
        color: $primary;
        opacity: 1;

        img {
          display: none;
          &.blueIcon {
            display: block;
          }
        }
      }
      &--nolink {
        color: $white;
      }
      &:hover {
        opacity: 1;
      }
      div {
        white-space: nowrap;
        color: $white;
        display: flex;
        gap: 0.75rem;
        font-weight: 600;
        span {
          width: 20px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    .nav {
      margin: 0 0 0.25rem 0;
      border: 0px;
      .nav__link {
        white-space: normal;
        border: 0px;
        padding-left: calc(1.5rem + 20px);
      }
    }
  }

  &__footer {
    margin-top: auto;
    &__link {
      font-size: 14px;
      color: $white;
      padding: 0.35rem 0.75rem;
      opacity: 0.7;
      cursor: pointer;
      display: block;

      &--active {
        opacity: 1;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
