$dropdown-border: rgba($border, 50%) !default;
$dropdown-radius: 0.25rem !default;
$dropdown-link-padding: 0.5rem 1rem !default;

// Setting position relative to set dropdown position relative to the parent element
.dropdown {
  position: absolute;
  left: 0px;
  top: 140%;
  background: $white;
  border: 1px solid $dropdown-border;
  border-radius: $dropdown-radius;
  padding: 0.5rem 0;
  min-width: 130px;
  z-index: 99;
  box-shadow: 0 4px 16px rgba($black, 10%);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  transition: opacity $ease-time ease-in-out, top $ease-time ease-in-out;

  &__toggle {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  &--aligned-right {
    left: inherit;
    right: 0;
  }

  .nav__item {
    display: block;
  }

  .nav__link {
    color: $black;
    text-decoration: none;
    padding: $dropdown-link-padding;
    display: block;
  }

  &--divider {
    background: rgba($black, 7%);
    height: 1px;
    margin: 0.5rem auto;
  }
  .show &,
  &.show {
    pointer-events: unset;
    opacity: 1;
    visibility: visible;
    top: 100%;
  }
}
