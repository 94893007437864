@import "../Base/colors";

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
a {
  cursor: pointer;
}
/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($black, 0.2);
  &:hover {
    background-color: $primary;
  }
}

*,
ul,
li,
ol {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.4;
}

// Box sizing
*,
::before,
::after {
  box-sizing: border-box;
}

// hidden everything on x-axis if greater than 100%
html {
  min-width: 320px;
  overflow-x: auto;
}

// Adding transition for mobile menu
body {
  transition: transform 500ms ease-in-out;
}

// Remove default margin
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

// Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed
ul[role="list"],
ol[role="list"],
ul,
ol,
li {
  list-style: none;
}

ol[type="decimal"] {
  list-style-type: decimal;
  margin-left: 1rem;
  li {
    list-style-type: decimal;
    padding-left: 0.25rem;
  }
}

// Set core root defaults
html:focus-within {
  scroll-behavior: smooth;
}

// Set core body defaults
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  background: #f7f7f7;
}
.sb-show-main.sb-main-padded {
  padding: 0;
  margin: 0;
}

// A elements must have eassing and that don't have a class get default styles
a {
  transition: all 300ms ease-in-out;
  border: none;
  color: $primary;
  text-decoration: none;
  &:not([class]) {
    text-decoration-skip-ink: auto;
  }
}

// Make images easier to work with
img,
picture {
  max-width: 100%;
  display: block;
}

small {
  font-size: 80%;
}

// Default line styling
hr {
  border: 0;
  border-top: 1px solid $border;
}

.s_sailo {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .s_sailo_content {
    flex-grow: 1;
  }
}

// Remove all animations, transitions and smooth scroll for people that prefer not to see them
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
