$xs: 0 !default;
$xxs: 379px !default;
$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
$xxl: 1400px !default;

$grid-breakpoints: (
  xs: $xs,
  xxs: $xxs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
);

// Default padding for grid cells
$gutter-x: 0.5rem !default;

.container {
  max-width: 100%;
  width: 100%;
  padding: 0 $gutter-x;
  margin: 0 auto;
  display: block;

  @media screen and (min-width: $sm) {
    max-width: 540px;
    padding: 0 $gutter-x;
  }
  @media screen and (min-width: $md) {
    max-width: 720px;
  }
  @media screen and (min-width: $lg) {
    max-width: 960px;
  }
  @media screen and (min-width: $xl) {
    max-width: 1140px;
  }
  @media screen and (min-width: $xxl) {
    max-width: 1320px;
  }
  &--fluid {
    width: 100%;
    padding: 0 $gutter-x;
  }
}

div[class^="col_"] {
  padding: 0 $gutter-x;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter-x;
  margin-right: -$gutter-x;

  > * {
    flex-shrink: 0;
    width: 100%;
  }
}

.s {
  &_flex {
    display: flex;

    &--inline {
      display: inline-flex;
    }

    &--nowrap {
      flex-wrap: nowrap;
    }

    &--wrap {
      flex-wrap: wrap;
    }

    &--grow {
      flex-grow: 1;
    }

    &--column {
      flex-direction: column;
    }
  }

  &_block {
    display: block;
  }

  &_justifyspace {
    &--between {
      justify-content: space-between;
    }

    &--end {
      justify-content: flex-end;
    }

    &--start {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }
  }

  &_alignitems {
    &--center {
      align-items: center;
    }

    &--end {
      align-items: flex-end;
    }

    &--start {
      align-items: flex-start;
    }
  }
}

.align {
  &--right {
    text-align: right !important;
  }
  &--center {
    text-align: center !important;
  }
}

// Grid loop
$columns: 12; // Max number of columns

.col_flex {
  width: 100%;

  &--inline {
    width: auto;
  }
}
// Grid cells for small devices
@for $i from 1 through $columns {
  .col_xs_#{$i} {
    flex: 0 0 auto;
    width: calc(100% / #{$columns} * #{$i});
  }
}

// Grid cells for small-medium devices
@media (min-width: $sm) {
  @for $i from 1 through $columns {
    .col_sm_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / #{$columns} * #{$i});
    }
    .col_sm_offset_#{$i} {
      margin-left: calc(100% / #{$columns} * #{$i});
    }
  }
}

// Grid cells for medium devices
@media (min-width: $md) {
  @for $i from 1 through $columns {
    .col_md_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / #{$columns} * #{$i});
    }
    .col_md_offset_#{$i} {
      margin-left: calc(100% / #{$columns} * #{$i});
    }
  }
}

// Grid cells for large devices
@media (min-width: $lg) {
  @for $i from 1 through $columns {
    .col_lg_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / #{$columns} * #{$i});
    }
    .col_lg_offset_#{$i} {
      margin-left: calc(100% / #{$columns} * #{$i});
    }
  }
}

// Grid cells for extra large devices
@media (min-width: $xl) {
  @for $i from 1 through $columns {
    .col_xl_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / #{$columns} * #{$i});
    }
    .col_xl_offset_#{$i} {
      margin-left: calc(100% / #{$columns} *#{$i});
    }
  }
}

// Grid cells for extra-extra large devices
@media (min-width: $xxl) {
  @for $i from 1 through $columns {
    .row > .col_xxl_#{$i} {
      flex: 0 0 auto;
      width: calc(100% / #{$columns} * #{$i});
    }
    .col_xxl_offset_#{$i} {
      margin-left: calc(100% / #{$columns} * #{$i});
    }
  }
}
