.addintake {
  @extend .page;

  &__form {
    background-color: $white;
    flex-grow: 1;
    overflow-y: auto;
    padding: 1rem;
    border: 1px solid $border;

    @media (min-width: $lg) {
      padding: 1rem 1.5rem;
    }
  }

  &__editpicture {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    gap: 1rem;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    &__img {
      width: 170px;
      height: 170px;
      border-radius: 100%;
      object-fit: cover;
      object-position: center;
      border: 2px solid $border;
      padding: 5px;
      outline: 0px solid transparent;
      transition: outline $ease-time ease-in-out;
      cursor: pointer;
      &:hover {
        outline: 4px solid rgba($success, 50%);
      }
    }

    @media (min-width: $sm) {
      display: inline-flex;
      flex-flow: row;
    }
  }
}
