.services{
  @extend .page;
  @media (min-width: $md) {
    overflow: hidden;
  }

  &__content{
    @extend .content;
    overflow: auto;

    table{
      table-layout: fixed;
      min-width: 850px;
    }
    th{
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @media (min-width: $lg) {
        font-size: 1rem;
      }
    }
    td{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      width: auto;
      vertical-align: middle !important;
      @media (min-width: $lg) {
        font-size: 1rem;
      }

      .flex{
        display: flex;
        align-items: center;
        gap: 0.5rem;
        @media (min-width: $lg) {
          gap: 1rem 2rem;
        }
      }

      .attr{
        &:not(:last-of-type){
          &:after{
            content: ', ';
          }
        }
      }
    }
  }

  &__paging{
    margin-top: 10px;
  }
}