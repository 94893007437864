// variables
$input-border-width: 1px;
$input-height-border: $input-border-width * 2;
$input-background: $white;
$input-focus-bg: $white;
$input-focus-color: $black;
$form-file-button-color: lighten($input-focus-color, 0.15);
$input-line-height: 1;
$form-bottom-margin: 1rem;
$input-grp-bg: transparent;
$group-icon-color: rgba($black, 0.4);

// Padding
$input-padding-y: 0.5rem;
$input-padding-x: 1rem;
$input-padding-y-sm: 0.32rem;
$input-padding-x-sm: 0.5rem;
$input-padding-y-lg: 0.571rem;
$input-padding-x-lg: 1rem;

// Font Size and font weight
$input-font-size: 1rem;
$input-font-size-sm: 0.75rem;
$input-font-size-lg: 1.25rem;
$input-font-weight: 400;

// Colors
$input-color: set-color($input-background);
$input-focus-color: set-color($input-background);
$text-muted: lighten($black, 0.3);
$input-placeholder-color: rgba($black, 0.4);

// Borders
$input-border-color: $border;
$input-focus-border-color: $primary;

// Transitions
$input-transition: border-color $ease-time ease-in-out,
  box-shadow $ease-time ease-in-out, outline-color $ease-time ease-in-out,
  background-color $ease-time ease-in-out;

// Disabled
$input-disabled-bg: #f2f2f2;
$input-disabled-color: #ddd;
$input-disabled-border-color: darken($border, 0.1);
$form-file-button-hover-bg: darken($border, 0.15);

// Form labels
$form-label-margin-bottom: 0.2rem;
$form-label-font-size: 0.85rem;
$form-label-font-style: normal;
$form-label-font-weight: 400;
$form-label-color: $text-color;

// Form Select
$form-select-indicator-padding: 2.25rem;
$form-select-indicator: "../../Assets/images/select-indicator.svg";

$control-radius: 0.25rem;
$control-shadow: none;
$control-focus-shadow: none;

$switch-width: 30px;
$form-switch-border-radius: $switch-width;
$form-switch-padding-start: calc($switch-width + 0.5em);
$form-switch-transition: background-position 0.2s ease-in-out,
  background-color 0.2s ease-in-out, outline $ease-time ease-in-out;
$form-switch-bg-image: "../../Assets/images/switch-indicator.svg";
$form-switch-checked-bg-image: "../../Assets/images/switch-indicator-active.svg";
$form-switch-checked-bg-position: right center;

// Checks and Radios
$line-height-base: 0.5em;
$form-check-input-width: 16px;
$form-check-inline-margin-end: 1rem;
$form-check-min-height: $input-font-size * $input-line-height;
$form-check-padding-start: 28px;
$form-check-margin-bottom: 0.125rem;
$form-check-label-cursor: pointer;
$form-check-transition: border-color 0.2s ease-in-out,
  background-color 0.2s ease-in-out;
$form-check-input-active-filter: brightness(0.9);
$form-check-input-focus-border: $success;
$form-check-input-focus-box-shadow: none;
$form-check-input-checked-bg-color: $success;
$form-check-input-checked-border-color: $success;
$form-check-input-checked-bg-image: "../../Assets/images/check-indicator.svg";
$form-check-input-indeterminate-bg-color: $success;
$form-check-input-indeterminate-border-color: $success;
$form-check-input-disabled-opacity: 0.5;
$form-check-label-disabled-opacity: 0.5;
$form-check-btn-check-disabled-opacity: 0.5;
$form-check-border-color: rgba($black, 0.3);
$form-check-input-indeterminate-border-color: "../../Assets/images/indeterminate-indicator.svg";
$form-check-radio-border-radius: 0.5;
$form-check-radio-checked-bg-image: "../../Assets/images/radio-indicator.svg";

$form-floating-height: add(3.5rem, $input-height-border);
$form-floating-line-height: 1.25;
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: 0.625rem;
$form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(0.85) translateY(-0.5rem)
  translateX(0.15rem);
$form-floating-transition: opacity $ease-time ease-in-out,
  transform 0.2s ease-in-out;

// Form group
.formgroup {
  margin-bottom: $form-bottom-margin;

  hr {
    margin: 0 !important;
  }
}

// Multiple fields
.multiFields {
  display: flex;
  gap: 1rem;

  &--disabled{
    background-color: #f2f2f2;
    border: 1px solid $border;
    border-radius: 0.25rem;
    opacity: 1;
    display: flex;
    align-items: center;
    height: 44px;

    .control{
      border: 0;
      text-overflow: ellipsis;
      height: 22px;
    }

    .btn{
      background-color: transparent;
      padding: 0 14px;
      &:hover{
        background-color: transparent;
        color: $danger;
      }
    }
  }

  &.error{
    flex-wrap: wrap;
    gap: 0 1rem;

    .control{
      width: calc(100% - calc(44px + 1rem));
    }
  }

  &__field {
    // transform: translateX(90%);
    position: relative;
    width: 100%;
    z-index: 1000;
    margin-top: 5px;
    .select__menu {
      margin-top: 0;
    }
  }
  .btn {
    aspect-ratio: 1/1;
    padding: 0.25rem;
  }
  + .multiFields {
    margin-top: 1rem;
  }
  > div {
    width: 100%;
  }
}

// Input labels
.label {
  margin-bottom: $form-label-margin-bottom;
  font-size: $form-label-font-size;
  font-style: $form-label-font-style;
  font-weight: $form-label-font-weight;
  color: $form-label-color;
  display: inline-block;
  min-height: 16px;
  line-height: 16px;

  @media (max-width: $sm) {
    &:empty {
      display: none;
    }
  }

  &--tooltip {
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 0.4rem !important;
    cursor: pointer;
    .asterisk {
      margin-left: -0.3rem;
    }
  }

  &--block {
    display: block;
  }

  &--lg {
    font-size: $form-label-font-size * 1.25;
  }

  &--sm {
    font-size: $form-label-font-size * 0.85;
  }

  &--label {
    margin-bottom: 1rem;
  }
}

// Input form control
.control {
  text-size-adjust: 100%;
  display: block;
  width: 100%;
  height: $control-height;
  padding: $input-padding-y $input-padding-x;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-background;
  background-clip: border-box;
  border: $input-border-width solid $input-border-color;
  appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  border-radius: $control-radius;
  box-shadow: $control-shadow;
  transition: $input-transition;
  outline: 0px solid transparent;

  &.error {
    border-color: $danger;
    margin: 0;
    &:focus {
      border-color: $danger;
      // outline-color: $danger;
    }
  }

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }

  &--caption {
    color: $text-muted;
    padding: 0.25rem;
  }

  &--number {
    padding-right: 0;
  }

  &--select {
    padding-right: $form-select-indicator-padding !important;
    -moz-padding-start: subtract($input-padding-x, 3px);
    background-color: $input-background;
    background-image: url($form-select-indicator);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1rem 0.75rem;
    appearance: none;
    cursor: pointer;

    &-noindicator {
      background-image: none;
    }

    &-allborder {
      border-right-width: 2px !important;
    }

    &-inline {
      width: auto;
      display: inline-block;
    }

    &[multiple],
    &[size]:not([size="1"]) {
      padding-right: $input-padding-x;
      background-image: none;
    }
  }

  &--textarea {
    min-height: 85px;
    resize: vertical;
    &-inline {
      width: auto;
      display: inline-block;
    }
  }

  &--search {
    background-image: url(../../Assets/images/search.svg);
    background-position: 1rem center;
    background-repeat: no-repeat;
    background-size: auto 40%;
    padding-left: 2.5rem;
  }

  &--icon-right {
    background-position: calc(100% - 0.75rem) center;
    padding-right: 2.5rem;
  }

  &[type="file"] {
    overflow: hidden; // prevent pseudo element button overlap
    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
    &::file-selector-button {
      width: 42px;
      height: 42px;
      border-radius: 0.25rem;
      border: 0px solid transparent;
      color: transparent;
      background: $success url('../../Assets/images/attach.svg') center no-repeat;
      background-size: 50%;
      &:hover {
        background: $success url('../../Assets/images/attach.svg') center no-repeat;
      }
    }
    
  }

  // Date picker
  &[type="date"],
  &[type="datetime-local"] {
    overflow: hidden;
    position: relative;
    background-image: url("../../Assets/images/calendar.svg");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 18px;
    padding-right: 50px;

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      color: transparent;
      cursor: pointer;
      height: auto;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      width: auto;
    }
    &:focus {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
      outline: 0px solid rgba($primary, 0.9);
      box-shadow: $control-focus-shadow;
    }
  }

  // Time picker
  &[type="time"] {
    overflow: hidden;
    position: relative;
    background-image: url("../../Assets/images/clock.svg");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 18px;
    padding-right: 50px;

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      color: transparent;
      cursor: pointer;
      height: auto;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      width: auto;
    }
    &:focus {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
      outline: 1px solid rgba($primary, 0.9);
      box-shadow: $control-focus-shadow;
    }
  }

  // Range slider
  &[type="range"] {
    margin: 0;
    width: 100%;
    min-height: 10px;
    max-height: 10px;
    padding: 0px;
    border: 0px;
    outline: 0px;
    background-color: rgba($primary, 0.2);
    background-image: linear-gradient($primary, $primary);
    background-size: 30% 100%;
    background-repeat: no-repeat;
    &:focus {
      border: 0px;
      outline: none;
      background-color: rgba($primary, 0.3);
    }
    &:active {
      &::-webkit-slider-thumb {
        box-shadow: 0px 0px 20px $primary;
        border: 4px solid rgba($white, 0.2);
      }
    }
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 10px;
      min-height: 10px;
      max-height: 10px;
      cursor: pointer;
      background: transparent;
      border-radius: 5px;
      border: none;
      box-shadow: none;
      outline: none;
      -webkit-appearance: none;
    }
    &::-moz-range-track {
      width: 100%;
      height: 10px;
      min-height: 10px;
      max-height: 10px;
      cursor: pointer;
      background: transparent;
      border-radius: 5px;
      border: none;
      box-shadow: none;
      outline: none;
      -webkit-appearance: none;
    }
    &::-ms-track {
      width: 100%;
      height: 10px;
      min-height: 10px;
      max-height: 10px;
      cursor: pointer;
      background: transparent;
      border-radius: 5px;
      border: none;
      box-shadow: none;
      outline: none;
      -webkit-appearance: none;
    }
    &::-webkit-slider-thumb {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: $primary;
      cursor: ew-resize;
      -webkit-appearance: none;
      margin-top: -10px;
      box-shadow: 0px 0px 10px $primary;
      border: 4px solid lighten($primary, 0.5);
    }
    &::-moz-range-thumb {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: $primary;
      cursor: ew-resize;
      -webkit-appearance: none;
      margin-top: -10px;
      box-shadow: 0px 0px 10px $primary;
      border: 1px solid $primary;
    }
    &::-ms-thumb {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: $primary;
      cursor: ew-resize;
      -webkit-appearance: none;
      margin-top: -10px;
      box-shadow: 0px 0px 10px $primary;
      border: 1px solid $primary;
    }
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    // outline: 1px solid $primary;
    box-shadow: $control-focus-shadow;
  }

  // Add some height to date inputs on iOS
  &::-webkit-date-and-time-value {
    height: if(
      unit($input-line-height) == "",
      $input-line-height * 1em,
      $input-line-height
    );
  }

  // Disabled and read-only inputs
  &:disabled,
  &[readonly] {
    color: rgba($black, 0.5);
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    opacity: 1;
    + label{
      opacity: .5;
    }
  }

  // File input buttons theming
  &::file-selector-button {
    padding: $input-padding-y $input-padding-x;
    margin: (-$input-padding-y) (-$input-padding-x);
    margin-inline-end: $input-padding-x;
    color: $form-file-button-color;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: $input-border-width;
    border-radius: 0; // stylelint-disable-line property-disallowed-list
    transition: background-color $ease-time ease-in-out;
  }

  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: $form-file-button-hover-bg;
  }

  &::-webkit-file-upload-button {
    padding: $input-padding-y $input-padding-x;
    margin: (-$input-padding-y) (-$input-padding-x);
    margin-inline-end: $input-padding-x;
    color: $form-file-button-color;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: $input-border-width;
    border-radius: 0; // stylelint-disable-line property-disallowed-list
  }

  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: $form-file-button-hover-bg;
  }

  // Small input variant
  &--sm {
    height: $control-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $input-font-size-sm;
    &::file-selector-button {
      padding: $input-padding-y-sm $input-padding-x-sm;
      margin: (-$input-padding-y-sm) (-$input-padding-x-sm);
      margin-inline-end: $input-padding-x-sm;
    }
    &::-webkit-file-upload-button {
      padding: $input-padding-y-sm $input-padding-x-sm;
      margin: (-$input-padding-y-sm) (-$input-padding-x-sm);
      margin-inline-end: $input-padding-x-sm;
    }
  }

  // Large input variant
  &--lg {
    min-height: $control-height-lg;
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $input-font-size-lg;
    &::file-selector-button {
      padding: $input-padding-y-lg $input-padding-x-lg;
      margin: (-$input-padding-y-lg) (-$input-padding-x-lg);
      margin-inline-end: $input-padding-x-lg;
    }
    &::-webkit-file-upload-button {
      padding: $input-padding-y-lg $input-padding-x-lg;
      margin: (-$input-padding-y-lg) (-$input-padding-x-lg);
      margin-inline-end: $input-padding-x-lg;
    }
  }
}

.checkbox {
  display: flex;
  gap: 10px;
  min-height: $form-check-min-height;
  margin-bottom: $form-check-margin-bottom;

  align-items: center;

  &--inline {
    display: inline-flex;
    max-width: 50%;
  }

  &__input {
    float: left;
    width: $form-check-input-width;
    min-width: $form-check-input-width;
    height: $form-check-input-width;
    background-color: $input-background;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid $form-check-border-color;
    border-radius: 3px;
    appearance: none;
    transition: $form-check-transition;
    cursor: $form-check-label-cursor;

    &[type="checkbox"] {
      border-radius: 3px;
    }

    &[type="radio"] {
      border-radius: 100%;
    }

    &:active {
      filter: $form-check-input-active-filter;
    }

    &:focus {
      border-color: $form-check-input-focus-border;
      outline: 4px solid rgba($primary, 0.3);
      box-shadow: $form-check-input-focus-box-shadow;
    }

    &:checked {
      background-color: $form-check-input-checked-bg-color;
      border-color: $form-check-input-checked-border-color;

      &[type="checkbox"] {
        background-image: url($form-check-input-checked-bg-image);
      }

      &[type="radio"] {
        background-image: url($form-check-radio-checked-bg-image);
      }
    }

    &[type="checkbox"]:indeterminate {
      background-color: $form-check-input-indeterminate-bg-color;
      border-color: $form-check-input-indeterminate-border-color;
      background-image: $form-check-input-indeterminate-border-color;
    }

    &:disabled {
      pointer-events: none;
      filter: none;
      opacity: $form-check-input-disabled-opacity;
      
      + label{
        opacity: .5;
      }
    }

    &[disabled],
    &:disabled {
      ~ .s-form-check-label {
        opacity: $form-check-label-disabled-opacity;
      }
    }
    &--inline {
      display: inline-block;
      margin-right: $form-check-inline-margin-end;
    }
  }

  &__label {
    cursor: $form-check-label-cursor;
    display: block;
    width: calc(100% - 1rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
  }

  &__btn {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
    cursor: $form-check-label-cursor;

    &[disabled],
    &:disabled {
      + .btn {
        pointer-events: none;
        filter: none;
        opacity: $form-check-btn-check-disabled-opacity;
      }
    }
    + .btn {
      cursor: $form-check-label-cursor;
      border: 1px solid $form-check-border-color;
      &:hover {
        background: $white;
      }
    }
    &:checked {
      + .btn {
        background: $primary;
        color: $white;
        border-color: $primary;
      }
    }
  }

  // Form Checkbox Groups
  &__group {
    display: flex;
    &-column {
      flex-direction: column;
    }
    .checkbox__btn {
      + .btn {
        border-radius: 0px;
        margin-right: -1px;
      }
      &:first-of-type {
        + .btn {
          border-radius: $control-radius 0 0 $control-radius;
        }
      }
      &:last-of-type {
        + .btn {
          border-radius: 0 $control-radius $control-radius 0;
        }
      }
    }
  }
}

// Switch
.switch {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  &__label {
    flex-grow: 1;
    display: block;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }

  &__input {
    width: $switch-width;
    height: 16px;
    margin: 0px;
    background-color: #eee;
    background-image: url($form-switch-bg-image);
    background-position: left center;
    background-repeat: no-repeat;
    border-radius: $form-switch-border-radius;
    transition: $form-switch-transition;
    cursor: $form-check-label-cursor;
    appearance: none;
    border: 1px solid $border;
    outline: 2px solid transparent;
    transform: translateY(-1px);
    &:disabled{
      opacity: .5;
      
      + label{
        opacity: .5;
      }
    }

    &:focus {
      border-color: rgba($black, 0.1);
      outline: 2px solid rgba($black, 0.1);
    }
    &:checked {
      background-position: $form-switch-checked-bg-position;
      background-image: url($form-switch-checked-bg-image);
      border-color: $success;
      background-color: $success;
      &:focus {
        border-color: $success;
        background-color: $success;
      }
    }
  }
}

// Form Radio Groups
.radiogroup {
  display: flex;
  gap: 1rem;
  .checkbox__btn {
    + .btn {
      border-radius: $control-radius;
      margin-right: -1px;
    }
    &:first-of-type {
      + .btn {
        border-radius: $control-radius 0 0 $control-radius;
      }
    }
    &:last-of-type {
      + .btn {
        border-radius: 0 $control-radius $control-radius 0;
      }
    }
  }
  &__item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    .label {
      margin: 0;
    }
    & input[type="radio"] {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
}

// Form Input Groups
.inputgroup {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;

  &__text {
    display: flex;
    align-items: center;
    padding: $input-padding-y $input-padding-x;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    text-align: center;
    white-space: nowrap;
    border: $input-border-width solid $input-border-color;
    border-radius: $control-radius;
    background-color: $input-grp-bg;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
  }

  &__icon {
    font-size: 11px;
    color: $group-icon-color;
  }

  &.error--bordred {
    .control {
      border-color: $danger;
      color: $danger;
    }
    .icon {
      color: $danger !important;
    }
    .inputgroup__text {
      border-color: $danger;
    }
  }

  > .control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    border: $input-border-width solid $input-border-color;

    &--select {
      padding-right: $form-select-indicator-padding * 0.75 !important;
      background-position: calc(100% - 0.25rem) center;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0;
    }
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
    }
  }

  > .input:focus,
  > .select:focus {
    z-index: 3;
  }

  .btn {
    position: relative;
    z-index: 2;

    &:focus {
      z-index: 3;
    }
  }
}

// Floating Labels
.floating {
  position: relative;

  > input,
  > select {
    height: $form-floating-height;
    line-height: $form-floating-line-height;
    padding-left: $form-floating-padding-x;
    padding-right: $form-floating-padding-x;
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;

    &::placeholder {
      color: transparent;
    }
    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: $form-floating-padding-y $form-floating-padding-x;
    pointer-events: none;
    border: $input-border-width solid transparent;
    transform-origin: 0 0;
    transition: $form-floating-transition;
  }

  > input:focus,
  > input:not(:placeholder-shown),
  > select {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }

  > input:-webkit-autofill {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
}

// Repeater
.repeater {
  .title {
    font-size: 1rem;
    font-weight: 600;
    margin: 1rem 0 0.5rem;
  }
  .row {
    gap: 0;
  }
  &__row {
    display: flex;
    gap: 1rem;
    align-items: center;
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    &__fields {
      flex: 1;
      display: flex;
      flex-flow: column;
      gap: 1rem;
      background-color: #f9f9f9;
      padding: 0.75rem 1rem 1rem;
      border: 1px solid $border;
      max-width: calc(100% - calc(44px + 1rem));
    }
    &__button {
      display: flex;
      gap: 1rem;
      .btn {
        width: 44px;
        padding: 0.25rem 0.5rem;
      }
    }
  }

  .repeater {
    .repeater__row__fields {
      background-color: $white;
    }
  }
}

// Tags
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  &__taxonomy {
    border-style: solid;
    border-width: 1px;
    border-color: #ddd;
    border-radius: 0.25rem;
    align-items: center;
    padding: 10px;
    max-height: 120px;
    min-height: 42px;
    height: auto;
    overflow: scroll;
    overflow-x: hidden;

    &__error {
      border-color: #ea415e;
      margin: 0;
      &:focus {
        border-color: $danger;
        outline-color: $danger;
      }
    }
  }

  &__tag {
    border: 1px solid $border;
    padding: 0.5rem 1rem;
    display: flex;
    gap: 0.5rem;
    font-size: 0.9rem;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.25rem;
    background-color: rgba($border, 20%);
    max-width: 100%;

    &__selected {
      background-color: #46bd9f;
      color: hsl(0deg, 0%, 100%);
      border: 1px solid transparent;
    }
    @media (max-width: 379px) {
      width: 100%;
    }
    span {
      font-size: 0.9rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  .btn {
    gap: 0.5rem;
    @media (min-width: $sm) {
      aspect-ratio: 1/1;
      padding: 0.25rem 0.5rem;
      span {
        display: none;
      }
    }
  }
}

// Days
.days {
  min-height: 44px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
  width: 100%;

  &__day {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    max-width: 100%;
  }

  input[type="checkbox"] {
    float: left;
    width: $form-check-input-width;
    min-width: $form-check-input-width;
    height: $form-check-input-width;
    background-color: $input-background;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid $form-check-border-color;
    border-radius: 3px;
    appearance: none;
    transition: $form-check-transition;
    cursor: $form-check-label-cursor;
    border-radius: 3px;

    &:active {
      filter: $form-check-input-active-filter;
    }
    &:focus {
      border-color: $form-check-input-focus-border;
      outline: 4px solid rgba($primary, 0.3);
      box-shadow: $form-check-input-focus-box-shadow;
    }
    &:checked {
      background-color: $form-check-input-checked-bg-color;
      border-color: $form-check-input-checked-border-color;
      &[type="checkbox"] {
        background-image: url($form-check-input-checked-bg-image);
      }
      &[type="radio"] {
        background-image: url($form-check-radio-checked-bg-image);
      }
    }
  }
}

input[type="checkbox"],
input[type="radio"]{
  + label{
    cursor: pointer;
  }
}