.tasks {
  @extend .page;

  &__filters {
    display: grid;
    gap: 0.5rem 1rem;

    @media (min-width: $xl) {
      grid-template-columns: 1fr 90px;
    }

    &__filter{
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.5rem 1rem;
      max-width: 1100px;
      
      @media (min-width: $sm) {
        grid-template-columns: 1fr 1fr;
      }

      @media (min-width: $xl) {
        grid-template-columns: repeat(3, 1fr) auto;
      }
    }

    &__sort{
      width: 90px;
      margin-left: auto;
      position: relative;

      .dropdown{
        left: inherit;
        right: 0;
      }
      
    .btn--white {
      position: relative;
      padding-right: 2.5rem;

      &:before {
        content: url("../../Assets/images/asc.svg");
        position: absolute;
        right: 16px;
        top: 13px;
      }

      &:after {
        content: url("../../Assets/images/desc.svg");
        position: absolute;
        right: 24px;
        top: 13px;
      }
    }
    }
  }
  
  .reflist__filters{
    display: grid;
    align-items: flex-end;
    gap: 0 1rem;
    grid-template-columns: repeat(2, 1fr);
    .reflist__filters__item{
      grid-column: span 2;
      &:last-of-type{
        grid-column: span 1;
        margin-top: 0.5rem;
      }
      &:nth-last-child(2){
        grid-column: span 1;
        margin-top: 0.5rem;
      }
    }
    @media (min-width: $sm) {
      .reflist__filters__item{
        grid-column: span 1;
      }
      grid-template-columns: repeat(3, 1fr);
      .reflist__filters__item{
        &:last-of-type{
          grid-column: span 2;
        }
      }
    }
    @media (min-width: $md) {
      grid-template-columns: repeat(4, 1fr) 100px;
      .reflist__filters__item{
        &:last-of-type{
          grid-column: span 1;
        }
      }
    }
    .reflist__filters__item{
      width: 100%;
    }
  }

  .reflist__listing {
    .reflist__table__row{
      grid-template-columns: 1fr;
      @media (min-width: $sm) {
        grid-template-columns: 1fr 1fr auto;
      }
      @media (min-width: $lg) {
        grid-template-columns: 50% 1fr auto;
      }
      &--full{
        grid-template-columns: 1fr;
        @media (min-width: $lg) {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
    .reflist__table__cell{
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      @media (min-width: $sm) {
        display: block;
      }
    }
    .reflist__table__cell{
      &:last-of-type{
        flex-flow: column ;
      }
    }
    .reflist__table__cell--priority{
      display: flex;
      flex-flow: column !important;
      gap: 0 1rem;
      @media (min-width: $lg) {
        align-items: flex-end;
      }
    }
  }
  
  .reflist__details__header{
    display: block;

    @media (min-width: $xxl) {
      display: flex;
      .reflist__details__header__title{
        margin-bottom: 0;
      }
    }
    .reflist__details__header__title{
      margin-bottom: 0.5rem;
    }
  }

  &__details{
    &__grid{
      display: grid;
      @media (min-width: $sm) {
        grid-template-columns: 1fr 1fr;
      }
      &:nth-child(even){
        background-color: $secondary;
      }
      &__cell{
        padding: 0.5rem 1rem;
      }
      &--edit{
        .tasks__details__grid__cell{
          align-self: center;
        }
      }
    }
  }

}