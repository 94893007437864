.locations {
  @extend .page;

  &__content {
    background-color: transparent;
    overflow: hidden;
  }

  &__listing {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
    padding-bottom: 1px;
  }

  &__list {
    background-color: $white;
    border-radius: 0.25rem;
    border: 1px solid $border;
    padding: 0.75rem 0.75rem 1rem;

    @media (min-width: $sm) {
      padding: 1rem 1.5rem;
    }
    &__head {
      display: flex;
      flex-flow: column;
      gap: 1rem;
      justify-content: space-between;
      @media (min-width: $sm) {
        flex-flow: row;
        align-items: center;
      }

      [class^="title--"] {
        small {
          display: block;
          font-weight: 400;
        }
      }

      .btn {
        padding: 0 1rem;
        height: 36px;
        @media (max-width: $sm) {
          border-color: inherit;
        }
        &:focus {
          outline: 0;
        }
      }
    }

    &__actions {
      display: flex;
      gap: 0.5rem 1rem;
      flex-wrap: wrap;
      @media (min-width: $sm) {
        gap: 0.5rem;
      }
    }

    &__body {
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: 1px solid $border;
      display: none;

      li,
      p,
      em,
      strong {
        font-size: 15px;
      }
      li {
        position: relative;
        padding-left: 1.5rem;
        &:before {
          content: "";
          border: 0;
          width: 6px;
          height: 6px;
          position: absolute;
          transform: rotate(45deg);
          left: 6px;
          top: 7px;
          background-color: $black;
        }
      }
      em {
        font-weight: normal;
        margin-top: 0.25rem;
        display: block;
        font-size: 14px;
      }
    }

    &--active {
      .locations__list__body {
        display: block;
      }
    }
  }

  .mode {
    padding: 0;
    li {
      white-space: unset;
    }

    .label {
      cursor: text;
      margin-bottom: 0.5rem;
    }
  }
}
