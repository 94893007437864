.chngPwd{
  @extend .page;

  &__content{
    @extend .content;
    padding: 1rem;

    @media (min-width: $md) {
      padding: 1.5rem;
    }
  }
}