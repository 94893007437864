.noauth {
  @extend .page;

  @media (min-width: $md) {
    background-color: $white;
    border: 1px solid $border;
    border-radius: 6px;
    height: 100vh;
  }

  &__content {
    border: 0;
    @extend .content;
    overflow-y: auto;
    background-color: transparent;
  }
  &__list {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    height: calc(100vh - 80px);
    @media (min-width: $md) {
      height: 100%;
    }
  }
  h1 {
    font-size: 30vw;
    font-weight: 900;
    line-height: 1;
    color: $warning;
    background: #333;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (min-width: $md) {
      font-size: 12vw;
    }
  }
  h2 {
    font-size: 20px;
    color: #666;
  }
  p {
    font-size: 16px;
    color: #666;
    margin: 10px 0 30px;
    text-align: center;
  }
  .btn {
    font-size: 18px;
    padding: 20px 40px;
  }
}
