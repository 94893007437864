.addService {
  @extend .page;

  &__content {
    @extend .content;
    padding: 1rem 1rem;
    @media (min-width: $md) {
      overflow: hidden;
      overflow-y: auto;
      padding: 1rem 1.5rem;
    }
  }

  &__actions {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
    @media (max-width: $md) {
      margin-bottom: 1rem;
    }
  }

  .table {
    tbody {
      tr {
        cursor: pointer;
      }
    }
    td,
    th {
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
      vertical-align: middle;
      small {
        font-size: 0.8rem;
        padding: 0 4px;
      }
    }
    @media (max-width: 479px) {
      display: block;
      thead {
        display: none;
      }
      tbody {
        display: block;
      }
      tr {
        display: flex;
        flex-flow: column;
        padding: 1rem 0;
        gap: 0.25rem;
      }
      td {
        border: 0;
        padding: 0 1rem;
        &:first-of-type {
          font-weight: 600;
        }
      }
    }
    .btn {
      display: flex;
      gap: 0.5rem;
      @media (max-width: 479px) {
        float: left;
        background-color: $danger;
        color: $white;
        margin-top: 0.5rem;
        &:hover {
          background-color: darken($danger, $btn-hover-darkness);
        }
      }
      span {
        @media (min-width: 479px) {
          display: none;
        }
      }
    }
  }

  .dropdown {
    width: 100%;
    display: none;
  }

  .show .dropdown {
    display: flex;
    flex-flow: column;
    padding: 1rem;
    gap: 1rem;
  }
}
