.viewer {
  @extend .page;
  height: calc(100vh - calc(1.5rem + 48px));

  @media (min-width: $md) {
    height: 100%;
  }

  &__content {
    @extend .content;
  }

  &__btnwrap {
    display: flex;
    gap: 16px;
    margin-top: 0.5rem;
  }
  &__frame {
    height: 100%;
  }

  .document__upload {
    width: 100%;
    flex: 1;
    &__box {
      border: 1px dashed #b6bed1;
      background-color: $secondary;
      border-radius: 4px;
      min-height: 100px;
      position: relative;
      overflow: hidden;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #8194aa;
      font-weight: 400;
      font-size: 15px;
      grid-column: span 2;
      &:hover {
        background-color: #f0f2f7;
      }
      input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
      }
      &__link {
        color: $primary;
        text-decoration: underline;
        font-size: 15px;
        cursor: pointer;
        &:hover {
          color: $success;
        }
      }
    }

    &__list {
      display: grid;
      gap: 1rem;
      &__box {
        display: grid;
        grid-template-columns: 100px 1fr;
        gap: 0 1rem;
      }
      .file-image {
        width: 100%;
        min-height: 85px;
        padding: 10px;
        background-size: cover;
        border-radius: 5px;
        margin-right: 15px;
        background-color: #eaecf1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: #475f7b;

        svg{
          height: 3rem;
        }

        img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 4px;
        }
      }
      .file-actions{
        display: grid;
        grid-template-columns: 1fr 44px;
        gap: 10px;

        .btn{
          align-self: end;
          height: 44px;
        }
      }
      .file-detail {
        h6{
          word-break: break-all;
        }
        .desc{
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          padding: 5px 0;
        }
        span{
          font-size: 14px;
        }
      }
    }
  }
}


.docload{
  .modal__header{
    display: grid;
    grid-template-columns: 1fr 60px;
    gap: 0;

    .btn{
      margin: 0 -3rem 0 0;
    }
  }
  .modal__body{
    padding: 20px 20px 0;
    overflow-y: auto;
    height: calc(100vh - calc(250px + 0.75rem));
    @media (min-width: $md) {
      height: auto;
      max-height: calc(100vh - calc(243px + 2.75rem));
    }
  }
}