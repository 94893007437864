.configure {
  @extend .page;
  &__content {
    @extend .content;
    display: flex;
    flex-flow: column;
  }
  &__header {
    display: flex;
    flex-flow: column;
    gap: 10px;

    @media (min-width: $sm) {
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
    }

    &__action {
      display: flex;
      align-items: center;
      @media (min-width: $sm) {
        justify-content: flex-end;
      }
      gap: 0 10px;
      .btn--sm {
        padding: 0 10px;
        height: 38px;
      }
    }
  }
  .hide {
    display: none;
  }
  &__listing {
    display: grid;
    height: 100%;
    flex-grow: 1;

    &--pdf {
      @media (min-width: $md) {
        grid-template-columns: 1fr 1fr;
      }
      @media (min-width: $lg) {
        grid-template-columns: 1fr 400px;
      }
      @media (min-width: $xxl) {
        grid-template-columns: 1fr 600px;
      }
      .configure__pdf {
        display: grid;
        grid-template-rows: 48px 42px 1fr;
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
      }
      .configure__form__row {
        grid-template-columns: 1fr;
        @media (min-width: $xl) {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
  &__form {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    @media (min-width: $md) {
      border-right: 1px solid $border;
    }
    &__header {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid $border;
      min-height: 48px;
      gap: 0 1rem;
      flex-wrap: wrap;
      .btn {
        padding: 0;
        height: auto;
      }
      &--paging {
        justify-content: space-between;
        h2 {
          width: 100%;
          @media (min-width: $sm) {
            width: auto;
          }
        }
        @media (min-width: $sm) {
          display: flex;
        }
      }
      &--action {
        display: flex;
        align-items: center;
        gap: 10px 1rem;
        margin-left: -10px;
        @media (min-width: $sm) {
          margin-left: auto;
        }
        p {
          font-size: 14px;
          line-height: 30px;
        }
        .btn--sm {
          height: 30px;
          width: 32px;
          transform: translateY(-1px);

          &:hover {
            svg {
              path {
                fill: $warning;
              }
            }
          }
        }
        svg {
          width: 14px;
          path {
            fill: $success;
            transition: fill 300ms ease;
          }
        }
      }
    }
    &__listing {
      overflow-y: auto;
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      gap: 5px;
      padding: 10px;
      @media (min-width: $md) {
        padding: 20px;
      }
    }
    &__list {
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      gap: 16px 0;
      padding: 10px 0px 30px;
      @media (min-width: $md) {
        padding: 20px 0px 40px;
      }
      &:not(:last-of-type) {
        border-bottom: 0px solid $border;
      }
      &--head {
        background-color: $success;
        color: $white;
        font-weight: bold;
        padding: 10px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        flex-flow: row;
        align-items: center;
        border-radius: 6px;
        @media (min-width: $md) {
          padding: 10px 20px;
        }
        .btn--clear {
          padding: 0px;
          height: auto;
          color: $white;
          &:hover {
            color: #ff0 !important;
          }
        }
      }
    }
    &__row {
      display: grid;
      gap: 16px;

      @media (min-width: $md) {
        grid-template-columns: 1fr 1fr;
      }
      @media (min-width: $sm) and (max-width: $md) {
        grid-template-columns: 1fr 1fr;
      }
      @media (min-width: $xl) {
        grid-template-columns: 1fr 1fr;
        gap: 16px 20px;
      }
      &--full{
        grid-template-columns: 1fr !important;
      }
      &__item {
        display: flex;
        flex-flow: column;
        gap: 8px 0;
        &--bot {
          align-self: flex-end;
        }
        .checkbox {
          align-items: flex-start;
          &__label {
            white-space: pre-wrap;
          }
        }
        .label {
          margin-bottom: -1px;
        }
        &--mapped {
          justify-content: center;
          align-items: flex-start;
          gap: 0;
          p {
            color: $success;
            font-style: italic;
          }
        }
        &--delete {
          justify-content: flex-end;
          flex-flow: row;
          .btn--danger {
            padding: 0;
            border: 0;
            background-color: transparent;
            &:hover,
            &:focus {
              border: 0;
              color: $danger;
              text-decoration: underline;
              background-color: transparent;
              outline: 0px !important;
            }
          }
        }
        .control--textarea {
          min-height: 80px;
        }
      }
      .repeater__row {
        @media (min-width: $sm) {
          grid-column: span 2;
        }
      }
      .width100 {
        width: 100px;
      }
      &--auto {
        gap: 10px 40px;
        display: flex;
        flex-wrap: wrap;
      }
      &--delete {
        grid-template-columns: 1fr;
      }
      &--p10 {
        padding: 10px 0;
      }
      &--pt34 {
        padding-top: 34px;
        align-self: start;
      }
      &--autoF {
        grid-template-columns: auto 1fr;
      }

      &--autoL {
        grid-template-columns: 1fr auto;
      }
    }
  }
  &__pdf {
    overflow: hidden;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: $white;
    opacity: 0;
    visibility: hidden;
    height: 100vh;
    @media (min-width: $md) {
      position: static;
      z-index: 0;
      height: 100%;
    }
    .react-pdf__Document {
      text-align: center;
    }
    .configure__form__header--action {
      margin-left: auto;
    }
    &__tools {
      display: flex;
      align-items: center;
      padding: 0 10px;
      justify-content: flex-end;
      gap: 0 5px;
      border-bottom: 1px solid $border;
      .btn {
        padding: 8px 12px;
        height: auto;
        svg {
          width: 20px;
          transition: fill 300ms ease;
        }
        &:hover {
          svg {
            fill: $success;
          }
        }
      }
    }
    &__view {
      padding: 0px;
      overflow: auto;
      .react-pdf__Page__canvas {
        margin: 0 auto;
      }
      .react-pdf__message,
      .react-pdf__message--loading {
        padding: 10px;
        text-align: center;
      }
    }
  }
  .item__deleted {
    .label {
      opacity: 0.5;
    }
  }
}
