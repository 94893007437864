.journey{
  @extend .page;

  &__content{
    @extend .content;
    display: flex;
    flex-flow: column;

    &__filter{
      padding: 0.5rem 0.75rem 0rem 0.75rem;
      border-bottom: 1px solid $border;
      @media (min-width: $sm) {
        padding: 0.5rem 1.5rem 0rem 1.5rem;
      }
      .formgroup{
        max-width: 460px;
      }
      .select__menu{
        z-index: 9;
      }
      .select__single-value{
        padding: 0 !important;
      }
      .select__option--is-focused{
        background-color: rgba($black, 5%);
        color: $primary;
      }
      .select__option--is-selected{
        background-color: rgba($primary, 5%);
        color: $primary;
      }
      .select__option,.select__single-value{
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 0.75rem 1rem;

        span{
          font-size: 14px;
          white-space: nowrap;
          color: $primary;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img{
          height: 20px;
          width: auto;
        }
      }
    }
  }

  &__listing{
    flex-grow: 1;
    overflow: hidden;
  }

  &__timeline{
    height: 100%;
    overflow-y: auto;
  }

  &__block{
    padding: 1rem 0.75rem 1rem 4.5rem;
    display: flex;
    overflow: hidden;
    flex-flow: column;
    position: relative;

    @media (min-width: $sm) {
      padding: 1.5rem;
      flex-flow: row;
      align-items: center;
    }

    &::after{
      content: '';
      z-index: 0;
      border: 0;
      width: 1px;
      position: absolute;
      left: calc(20px + 0.75rem);
      transform: translateX(-50%);
      top: -1.5rem;
      bottom: -1.5rem;
      background-color: $primary;

      @media (min-width: $sm) {
        left: calc(140px + 1.5rem);
      }
    }

    &:hover{
      .journey__block__desc,.journey__block__date{
        color: $primary;
        text-decoration: underline;
      }
      .journey__block__icon{
        transform: scale(1.2);
        .journey__block__img{
          transform: scale(0.8);
        }
      }
    }

    &__date{
      font-size: 0.9rem;
      width: 100%;
      min-width: 100%;
      margin-bottom: 0.25rem;

      @media (min-width: $sm) {
        width: 120px;
        min-width: 120px;
        margin-bottom: 0;
      }
    }

    &__desc{
      font-weight: bold;
      padding-left: 0;
      font-size: 0.9rem;
      order: 3;

      @media (min-width: $sm) {
        padding-left: 34px;
        font-size: 1rem;
      }
    }

    &__icon{
      border: 1px solid $primary;
      width: 40px;
      height: 40px;
      min-width: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform $ease-time ease-in-out;
      position: relative;
      background-color: $white;
      z-index: 1;
      order: 1;
      position: absolute;
      left: 0.75rem;
      top: 1rem;
      z-index: 1;

      @media (min-width: $sm) {
        position: static;
      }
      
    }

    &__img{
      height: 22px;
      width: auto;
      transition: transform $ease-time ease-in-out;
    }
  }
}