.rolemodal{
  .modal{
    &__body{
      padding-top: 1rem;
      padding-bottom: 0;
    }
    &__header{
      border-bottom: 1px solid $border;
      position: relative;
      .btn{
        position: absolute;
        right: 1rem;
      }
    }
  }
  &__accordian{
    display: flex;
    flex-flow: column;
    gap: 16px 0;
    margin-top: 1rem;
  }
  &__item{
    display: flex;
    flex-flow: column;
    border: 1px solid $border;
    border-radius: 6px;
    overflow: hidden;
  }
  &__radio{
    opacity: 0;
    position: absolute;
    &:checked {
      + .rolemodal__header {
        color: $primary;
        .rolemodal__icon {
          transform: rotate(180deg);
        }
      }
      ~ .rolemodal__content {
        display: grid;
      }
    }
  }
  &__header{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    gap: 0 20px;
    padding: 16px;
    position: relative;
  }
  &__title{
    font-weight: bold;
    line-height: 22px;
  }
  &__content{
    display: none;
    gap: 16px;
    padding: 0 16px 16px;
  }
  .checkbox{
    align-items: flex-start;
    &__label{
      white-space: normal;
      line-height: 20px;
    }
  }
}
