// Color contarst mixin
@function color-set($color) {
  @if (lightness($color) > 50) {
    @return rgba($black, 80%);
  } @else {
    @return $white;
  }
}

// Colors
$primary: #004a93 !default;
$secondary: #f1f1f1 !default;
$success: #46bd9f !default;
$warning: #ff7700 !default;
$danger: #ea415e !default;
$info: #0088ff !default;
$black: #000 !default;
$white: hsl(0, 0%, 100%) !default;
$text-muted: rgba($black, 50%) !default;
$lightbg: #f9f9f9 !default;
$yellow: #ffd360 !default;
$blue: #2684ff !default;

// Green text color
.color {
  &--muted {
    color: rgba($black, 50%);
  }

  &--success {
    color: $success;
  }

  &--yellow {
    color: $yellow;
  }

  // Red text color
  &--danger {
    color: $danger;
  }

  // Warning text color
  &--warning {
    color: darken($warning, 15%);
  }

  // White text color
  &--white {
    color: $white;
  }

  // Blue text color
  &--primary {
    color: $primary;
  }

  // Black text color
  &--black {
    color: $black;
  }
}

.bg {
  // Red background
  &--danger {
    background: $danger;
    border-color: $danger;
    color: color-set($danger);
  }

  // Info background
  &--info {
    background: $info;
    border-color: $info;
    color: color-set($info);
  }

  // Info background
  &--success {
    background: $success;
    border-color: $success;
    color: color-set($success);
  }
}
