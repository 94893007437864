.myprofile {
  @extend .page;

  &__headingaction {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__editpicture {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    gap: 1rem;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    &__img {
      width: 170px;
      height: 170px;
      border-radius: 100%;
      object-fit: cover;
      object-position: center;
      border: 2px solid $border;
      padding: 5px;
      outline: 0px solid transparent;
      transition: outline $ease-time ease-in-out;
      cursor: pointer;
      &:hover {
        outline: 4px solid rgba($success, 50%);
      }
    }

    @media (min-width: $sm) {
      display: inline-flex;
      flex-flow: row;
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 1rem;
    @media (max-width: $sm) {
      flex-flow: column;
      gap: 0.5rem;
      margin-bottom: 1.5rem;
    }

    &__img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: center;
      border-radius: 100%;
      border: 2px solid $border;
    }
  }

  &__content {
    background-color: $white;
    padding: 1rem 0.75rem;
    border: 1px solid $border;
    flex: 1;
    @media (min-width: $md) {
      padding: 1rem 1.5rem;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .formgroup {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  &__value {
    border-bottom: 1px solid $border;
    margin-bottom: 2rem;
    color: $black;
    font-weight: 600;
    flex-grow: 1;
    font-size: 14px;

    li {
      position: relative;
      font-size: 14px;
      padding-left: 1rem;
      &:before {
        content: "";
        border: 0px;
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        background-color: #000;
        position: absolute;
        left: 5px;
        top: 7px;
      }
      p {
        margin-bottom: 5px;
      }
      small {
        color: rgba(0, 0, 0, 0.7);
        font-weight: 600;
        display: block;
        font-size: 14px;
        margin: 0px 0px 2px;
        font-style: italic;
      }
    }
  }
}
