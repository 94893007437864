$table-bg: $white !default;
$table-cell-padding: 1rem !default;
$table-cell-padding-y: 0.5rem !default;
$table-cell-padding-x: 0.5rem !default;
$table-cell-padding-y-sm: 0.25rem !default;
$table-cell-padding-x-sm: 0.25rem !default;
$table-cell-vertical-align: top !default;
$table-th-font-weight: 600 !default;
$table-border-color: rgba($border, 50%) !default;
$table-bg-accent: rgba($black, 3%) !default;
$table-bg-hover: rgba($warning, 10%) !default;

.table__responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  // Resposive table breakpoints
  @each $infix, $bp in $grid-breakpoints {
    @media screen and (max-width: $bp) {
      &--#{$infix} {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
}

.table {
  background-color: $table-bg;
  width: 100%;
  border: 1px solid $table-border-color;
  border-collapse: collapse;
  color: set-color($table-bg);
  text-align: left;

  &--col[class*="col"] {
    position: static; // Prevent border hiding in Firefox and IE9-11
    display: table-column;
    float: none;
  }

  &__actions{
    display: grid;
    grid-template-columns: repeat(3, 34px);
    gap: 0 0.25rem;
  }

  td,
  th {
    &-align-center {
      text-align: center;
    }

    &-align-right {
      text-align: right;
    }

    &[class*="col"] {
      position: static; // Prevent border hiding in Firefox and IE9-11
      display: table-cell;
      float: none;
    }
  }

  &--caption {
    padding-top: $table-cell-padding;
    padding-bottom: $table-cell-padding;
    color: $text-muted;
    text-align: left;
  }

  // Cells
  thead,
  tbody,
  tfoot {
    tr {
      th,
      td {
        padding: $table-cell-padding;
        vertical-align: top;
        border: 1px solid $table-border-color;
      }
    }
  }

  &--color-danger {
    color: $danger;
  }

  &--color-success {
    color: $success;
  }

  // Bordered Table
  &--bordered {
    border: 0;

    thead,
    tbody,
    tfoot {
      tr {
        th,
        td {
          border: 0;
          border-top: 1px solid $table-border-color;
        }
      }
    }

    thead {
      tr {
        th,
        td {
          border-bottom-width: 2px;
        }
      }
    }
  }

  // Striped Table
  &--striped {
    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: $table-bg-accent;
        }
      }
    }
  }

  // Text aligned middle
  &--align-middle {
    thead,
    tbody,
    tfoot {
      tr {
        th,
        td {
          vertical-align: middle;
        }
      }
    }
  }

  // Table Sortable
  &--sortable {
    thead {
      tr {
        th {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &-icon {
      position: relative !important;
      padding-right: $table-cell-padding * 1.5 !important;
      cursor: pointer;
      &:before,
      &:after {
        font-family: "sailo_icons" !important;
        font-size: 0.8rem;
        position: absolute;
        top: 52%;
        transform: translateY(-50%);
        color: rgba($black, 20%);
      }
      &:before {
        content: "\e902";
        right: 4px;
      }
      &:after {
        content: "\e90d";
        right: 13px;
      }
      &--asc {
        &:before {
          color: $black;
        }
      }
      &--desc {
        &:after {
          color: $black;
        }
      }
    }
  }

  // Table Hover
  &--hover {
    tbody {
      tr {
        transition: background-color $ease-time ease-in-out;

        &:nth-child(odd),
        &:nth-child(even) {
          &:hover {
            background-color: $table-bg-hover;
          }
        }
      }
    }
  }
}
