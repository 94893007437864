.alias {
  @extend .page;
  &__content {
    @extend .content;
    display: flex;
    flex-flow: column;
    padding: 16px;
    @media (min-width: $md) {
      padding: 20px;
    }
  }
  .reflist__listing {
    height: calc(100% - calc(110px + 1rem));

    &.active{
      @media (min-width: $xl) {
        .reflist__table__row{
          grid-template-columns: repeat(2, 1fr);
          .reflist__table__cell{
            &:last-child{
              text-align: left;
            }
          }
        }
      }
      @media (min-width: $xxl) {
        .reflist__table__row{
          grid-template-columns: repeat(4, 1fr);
          .reflist__table__cell{
            &:last-child{
              text-align: left;
            }
          }
        }
      }
    }

    &:not(.active){
      @media (min-width: $sm) {
        .reflist__table__row{
          grid-template-columns: repeat(2, 1fr);
          .reflist__table__cell{
            &:last-child{
              text-align: left;
            }
          }
        }
      }
      @media (min-width: $xl) {
        .reflist__table__row{
          grid-template-columns: repeat(4, 1fr);
          .reflist__table__cell{
            &:last-child{
              text-align: left;
            }
          }
        }
      }
    }
  }
  &__wrap {
    overflow: hidden;
    display: flex;
    flex-flow: column;
  }
  &__filters {
    display: grid;
    gap: 0.5rem 1rem;
    padding-bottom: 10px;
    .btn--inline{
      width: 100%;
    }

    @media (min-width: $sm) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: $lg) {
      grid-template-columns: repeat(2, auto) 120px 100px;
    }

    @media (min-width: $xl) {
      grid-template-columns: repeat(2, 29.5%) 120px 100px;
    }
    &__item {
      position: relative;
      &:nth-last-child(1),
      &:nth-last-child(2) {
        align-self: end;
      }
    }
    .show {
      .dropdown {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__create {
    overflow: hidden;
    overflow-y: auto;
  }
  &__btnwrap {
    display: flex;
    align-items: center;
    gap: 0 16px;
  }
  &__mapping {
    display: grid;
    background-color: $white;
    border: 1px solid $border;
    flex-grow: 1;
    overflow: hidden;
    grid-template-columns: 40% 40% 20%;
    @media (min-width: $xl) {
      grid-template-columns: 35% 40% 25%;
    }
    .checkbox {
      margin-bottom: 0;
      align-items: unset;
      &__label {
        line-height: 18px;
        font-size: 14px;
        white-space: unset;
        strong {
          font-size: 14px;
        }
      }
    }
    &__header {
      display: flex;
      flex-flow: column;
      gap: 5px 0;
      padding: 16px 20px;
      height: 103px;

      &__filter {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 16px;
        flex-grow: 1;
      }
    }
    &__content {
      border-top: 1px solid $border;
      padding: 16px;
      flex-grow: 1;
      overflow-y: auto;
    }
  }
  &__relation {
    border-right: 1px solid $border;
    border-left: 1px solid $border;
    display: flex;
    flex-flow: column;
    h2 {
      padding: 0 10px;
    }
    .alias__mapping__content {
      padding: 0 10px;
    }
    &__item {
      padding: 0 10px;
      border-bottom: 1px solid $border;
      border-right: 1px solid $border;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
  }
  &__source {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
    .checkbox {
      align-items: center;
      padding: 4px 0;
    }
    &__item {
      display: grid;
      gap: 10px;
      padding: 20px 0;
      &:not(:last-of-type) {
        border-bottom: 1px solid $border;
      }
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px 20px;
        h4 {
          line-height: 20px;
        }
        .switch {
          line-height: 24px;
          align-items: center;
        }
      }
    }
    &__field {
      display: flex;
      flex-flow: column;
      gap: 2px 0;
      &--full {
        @media (min-width: $xl) {
          grid-column: span 2;
        }
      }
    }
    &__label {
      font-size: 14px;
      line-height: 16px;
    }
    &__value {
      font-weight: 600;
      line-height: 18px;
      font-size: 14px;
    }
  }
  &__target {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
    border-left: 1px solid $border;
    border-right: 1px solid $border;
    &__item {
      display: grid;
      grid-template-columns: 220px 1fr;
      &:first-of-type {
        .alias__source__item {
          margin-top: 16px;
        }
      }
      &:last-of-type {
        .alias__source__item {
          margin-bottom: 16px;
          border-bottom: 1px solid $border;
        }
        .alias__relation__item {
          border-bottom: 0;
        }
      }
    }
    .alias {
      &__source {
        &__item {
          grid-template-columns: 1fr;
          gap: 16px;
          
        }
        &__field {
          &--set {
            display: flex;
            flex-flow: column;
            gap: 10px;
          }
          &--select{
            margin-top: 10px;
          }
        }
      }
      &__mapping {
        &__header {
          padding: 16px 10px;
          + .alias__mapping__header {
            padding: 16px;
            border: 0;
          }
        }
      }
    }
  }
  .table {
    border: 0;
    tbody tr td {
      padding: 10px;
      border: 0;
      font-size: 14px;
    }
  }
  &__block {
    display: flex;
    gap: 16px;
    flex-flow: column;
    padding: 16px 0;
    &:not(:last-of-type){
      border-bottom: 1px solid $border;
    }
   
    &__table {
      display: grid;
      gap: 10px;
      &:not(:last-of-type){
        margin-bottom: 20px;
      }
    }
    &__row{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 7px 0;

      .loop{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        grid-column: span 2;

        + .loop{
          margin-top: 20px;
        }
      }
    }

    &__title {
      font-size: 15px;
      font-weight: bold;
      grid-column: span 2;
      &--sub {
        font-weight: 500;
        font-size: 14px;
        grid-column: span 2;
      }
    }
    &__cell {
      font-size: 14px;
      &--full {
        grid-column: span 2;
        display: flex;
        gap: 10px;
        font-size: 14px;
        flex-flow: column;
      }
      .title--h5{
        strong {
          font-size: 1.1rem;
        }
      }
    }
  }
  &__mapped{
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
    &__row{
      display: flex;
      flex-flow: column;
      gap: 8px;
      padding: 16px 0;
      &:not(:last-of-type){
        border-bottom: 1px solid $border;
      }
    }
    .alias__source__field{
      flex-flow: row;
      gap: 0 10px;
      .alias__source__label{
        min-width: 50px;
      }
      &--action{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0 30px;
        margin-top: 10px;
        strong{
          font-size: 14px;
          color: $success;
          line-height: 20px;
          display: block;
          transform: translateY(1px);
        }
        .btn--danger{
          &:hover{
            background-color: transparent;
            color: $danger;
          }
        }
      }
    }
  }
  h5.color--success{
    margin: 30px 0 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid $success;
  }
}
