.screening{
  @extend .page;

  &__content{
    @extend .content;
    padding: 1rem 1.5rem;
    overflow-y: auto;

    @media (min-width: $md) {
      padding: 1rem;
    }
  }
  &__btnwrap{
    display: flex;
    align-items: center;
    gap: 0 16px;
    margin-top: 0.5rem;
  }
  .label{
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
    + .radiogroup{
      margin-top: 1rem;
      .label{
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 400;
        color: $black;
      }
    }
  }
  table{
    width: 100%;
    border-collapse: collapse;
    tr{
      border-top: 1px solid $border;
    }
    td,th{
      padding: 12px 16px;
    }
  }
  input[type="number"].control{
    max-width: 300px;
  }
  &__row{
    &:not(:last-of-type){
      margin-bottom: 1rem;
      @media (min-width: $md) {
        margin-bottom: 2rem;
      }
    }
  }
}